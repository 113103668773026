import { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import { APICallStatus, RIOError, RIOThunkAction } from 'Models/common/types';
import {
  addDeviceLabelApi,
  cancelDeviceLogUpload as cancelDeviceLogUploadApi,
  deleteDeviceApi,
  deleteDeviceLabelApi,
  deleteDeviceLog as deleteDeviceLogApi,
  downloadDeviceLog as downloadDeviceLogApi,
  executeCommandApi,
  getAuthCredsForDeviceApi,
  getDaemonsApi,
  getDeviceDeploymentsListApi,
  getDeviceDetailsApi,
  getDeviceLogDetails as getDeviceLogDetailsApi,
  getDeviceLogsList,
  getDevicesApi,
  getTokenApi,
  getUnameApi,
  migrateDeviceApi,
  saveVPNDetailsApi,
  sshStartApi,
  updateDeviceDescriptionApi,
  updateDeviceLabelApi,
  updateNameAndDescriptionApi,
  updateNameApi,
} from 'Models/devices/service';
import {
  AddDevice,
  AddDeviceResponse,
  DaemonsApiResponse,
  DetailsError,
  Device,
  DeviceDeploymentsPayload,
  DeviceDetails,
  DevicesAction,
  DeviceStatus,
  Label,
  LogDetail,
  MigrateDevicePayload,
  ReqCmd,
  ReqDeviceLogList,
  ReqDownloadDeviceLog,
  ResDeviceLog,
  SaveVPNDetailsPayload,
  TerminalData,
  UpdateNameAndDescriptionPayload,
  UpdateStatus,
} from 'Models/devices/types';
import { changeSelectedProject, copyToClipboard } from 'RioRedux/common/actions';
import {
  deviceDeleteStatuses,
  deviceStatus,
  getDeviceCurlScript,
  getDeviceDeleteErrorMessage,
  getDeviceDetailsErrorMessage,
} from 'Root/devices/utils';
import { getNetworkListApi } from 'Root/models/networks/service';
import { Network } from 'Root/models/networks/types';
import { NotificationPayload } from 'Root/models/notifications/types';
import { PackageDeploymentList } from 'Root/models/packages/types';
import { getMessageFromError } from 'Root/shared/utils/common';
import { trackEvent, trackTiming } from 'Shared/utils/analytics';
import { getErrorMessage } from 'Shared/utils/core';
import { subtractDates } from 'Shared/utils/core/date';
import history from 'Shared/utils/history';

import {
  dismissNotification,
  setNotificationFail,
  setNotificationInfo,
  setNotificationSuccess,
} from '../notifications/action';

import ActionTypes from './actionTypes';

const track = (event: string, startDate: Date, type: 'success' | 'error') => {
  const delta = subtractDates(new Date(), startDate);
  trackTiming('Catalog', event, delta, type);
};

const trackSuccess = (event: string, startDate: Date) => {
  track(event, startDate, 'success');
};

const trackError = (event: string, startDate: Date) => {
  track(event, startDate, 'error');
};

const setDevicesPending = (): DevicesAction => ({
  type: ActionTypes.DEVICES_GET_PENDING,
});

const setDevicesFulfilled = (payload: Device[]): DevicesAction<Device[]> => ({
  type: ActionTypes.DEVICES_GET_FULFILLED,
  payload,
});

const setDevicesRejected = (payload: RIOError): DevicesAction<RIOError> => ({
  type: ActionTypes.DEVICES_GET_REJECTED,
  payload,
});

const addDevicePending = (): DevicesAction => ({
  type: ActionTypes.DEVICE_ADD_PENDING,
});

const addDeviceFulfilled = (payload: AddDeviceResponse): DevicesAction<AddDeviceResponse> => ({
  type: ActionTypes.DEVICE_ADD_FULFILLED,
  payload,
});

const addDeviceRejected = (): DevicesAction => ({
  type: ActionTypes.DEVICE_ADD_REJECTED,
});

const deleteDevicePending = (payload: UpdateStatus): DevicesAction<UpdateStatus> => ({
  type: ActionTypes.DEVICE_DELETE_PENDING,
  payload,
});

const deleteDeviceFulfilled = (payload: UpdateStatus): DevicesAction<UpdateStatus> => ({
  type: ActionTypes.DEVICE_DELETE_FULFILLED,
  payload,
});

const deleteDeviceRejected = (payload: UpdateStatus): DevicesAction<UpdateStatus> => ({
  type: ActionTypes.DEVICE_DELETE_REJECTED,
  payload,
});

const getTokenRejected = (): DevicesAction => ({
  type: ActionTypes.GET_DEVICE_TOKEN_REJECTED,
});

const addLabelPending = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_ADD_LABEL_PENDING,
  payload,
});

const addLabelFulfilled = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_ADD_LABEL_FULFILLED,
  payload,
});

const addLabelRejected = (): DevicesAction => ({
  type: ActionTypes.DEVICE_ADD_LABEL_REJECTED,
});

const deleteLabelPending = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_LABEL_DELETE_PENDING,
  payload,
});

const deleteLabelFulfilled = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_LABEL_DELETE_FULFILLED,
  payload,
});

const deleteLabelRejected = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_LABEL_DELETE_REJECTED,
  payload,
});

const updateLabelPending = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_LABEL_UPDATE_PENDING,
  payload,
});

const updateLabelFulfilled = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_LABEL_UPDATE_FULFILLED,
  payload,
});

const updateLabelRejected = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_LABEL_UPDATE_REJECTED,
  payload,
});

export const setSelectedDeviceID = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.SET_SELECTED_DEVICE_ID,
  payload,
});

export const dirtyDeviceLabelAddKey = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_DIRTY_ADD_LABEL_KEY,
  payload,
});

export const dirtyDeviceLabelAddValue = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_DIRTY_ADD_LABEL_VALUE,
  payload,
});

const onlineDevicesPending = (): DevicesAction => ({
  type: ActionTypes.PACKAGE_DEPLOYMENT_DEVICES_GET_PENDING,
});

const onlineDevicesFulfilled = (payload: Device[]): DevicesAction<Device[]> => ({
  type: ActionTypes.PACKAGE_DEPLOYMENT_DEVICES_GET_FULFILLED,
  payload,
});

const onlineDevicesRejected = (): DevicesAction => ({
  type: ActionTypes.PACKAGE_DEPLOYMENT_DEVICES_GET_REJECTED,
});

const getUnamePending = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_UNAME_GET_PENDING,
  payload,
});

const getUnameFulfilled = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_UNAME_GET_FULFILLED,
  payload,
});

const getUnameRejected = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_UNAME_GET_REJECTED,
  payload,
});

const updateDescPending = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_DESCRIPTION_UPDATE_PENDING,
  payload,
});

const updateDescFulfilled = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_DESCRIPTION_UPDATE_FULFILLED,
  payload,
});

const updateDescRejected = (): DevicesAction => ({
  type: ActionTypes.DEVICE_DESCRIPTION_UPDATE_REJECTED,
});

const updateNamePending = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_NAME_UPDATE_PENDING,
  payload,
});

const updateNameFulfilled = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_NAME_UPDATE_FULFILLED,
  payload,
});

const updateNameRejected = (): DevicesAction => ({
  type: ActionTypes.DEVICE_NAME_UPDATE_REJECTED,
});

const getDeviceDetailsPending = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_DETAILS_GET_PENDING,
  payload,
});

export const getDeviceDetailsFulfilled = (
  payload: DeviceDetails,
): DevicesAction<DeviceDetails> => ({
  type: ActionTypes.DEVICE_DETAILS_GET_FULFILLED,
  payload,
});

const getDeviceDetailsRejected = (payload: DetailsError): DevicesAction<DetailsError> => ({
  type: ActionTypes.DEVICE_DETAILS_GET_REJECTED,
  payload,
});

const dirtyLabelKey = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_DIRTY_LABEL_KEY,
  payload,
});

const dirtyLabelValue = (payload: Label): DevicesAction<Label> => ({
  type: ActionTypes.DEVICE_DIRTY_LABEL_VALUE,
  payload,
});

const getPackageDeploymentDevicesPending = (): DevicesAction => ({
  type: ActionTypes.PACKAGE_DEPLOYMENT_DEVICES_GET_PENDING,
});

const execCmdPending = (payload: ReqCmd): DevicesAction<ReqCmd> => ({
  type: ActionTypes.DEVICE_COMMAND_POST_PENDING,
  payload,
});

const execCmdFulfilled = (payload: TerminalData): DevicesAction<TerminalData> => ({
  type: ActionTypes.DEVICE_COMMAND_POST_FULFILLED,
  payload,
});

const execCmdRejected = (): DevicesAction => ({
  type: ActionTypes.DEVICE_COMMAND_POST_REJECTED,
});

const deleteDeviceInUseWarn = (payload: DetailsError): DevicesAction<DetailsError> => ({
  type: ActionTypes.ADD_DEVICE_DELETE_IN_USE_WARNING,
  payload,
});

export const toggleNameEdit = (): DevicesAction => ({
  type: ActionTypes.TOGGLE_DEVICE_NAME_EDIT_MODE,
});

export const dirtyDeviceName = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DIRTY_DEVICE_NAME,
  payload,
});

export const setCurrentPageId = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DEVICE_PAGE_SET_ID,
  payload,
});

export const toggleDescriptionEdit = (): DevicesAction => ({
  type: ActionTypes.TOGGLE_DEVICE_DESCRIPTION_EDIT_MODE,
});

export const dirtyDeviceDescription = (payload: string): DevicesAction<string> => ({
  type: ActionTypes.DIRTY_DEVICE_DESCRIPTION,
  payload,
});

export const removeDeviceInUseWarning = (): DevicesAction => ({
  type: ActionTypes.REMOVE_DEVICE_DELETE_IN_USE_WARNING,
});

export const addModalOpenAction = (): DevicesAction => ({
  type: ActionTypes.ADD_DEVICE_MODAL_OPEN,
});

export const addModalCloseAction = (): DevicesAction => ({
  type: ActionTypes.ADD_DEVICE_MODAL_CLOSE,
});

export const setLogsApiStatus = (payload: APICallStatus): DevicesAction<APICallStatus> => ({
  type: ActionTypes.SET_LOGS_API_STATUS,
  payload,
});

export const setLogsData = (payload: ResDeviceLog): DevicesAction<ResDeviceLog> => ({
  type: ActionTypes.SET_LOGS_DATA,
  payload,
});

export const setDeviceDeploymentList = (
  payload: PackageDeploymentList[],
): DevicesAction<PackageDeploymentList[]> => ({
  type: ActionTypes.SET_DEVICE_DEPLOYMENTS_LIST,
  payload,
});

export const setDeviceDeploymentListAPICallStatus = (
  payload: APICallStatus,
): DevicesAction<APICallStatus> => ({
  type: ActionTypes.SET_DEVICE_DEPLOYMENTS_LIST_API_STATUS,
  payload,
});

export const setMigrateDeviceApiStatus = (
  payload: APICallStatus,
): DevicesAction<APICallStatus> => ({
  type: ActionTypes.SET_MIGRATE_DEVICE_API_STATUS,
  payload,
});

export const setDaemons = (payload: DaemonsApiResponse): DevicesAction<DaemonsApiResponse> => ({
  type: ActionTypes.SET_DAEMONS,
  payload,
});

export const setDaemonsApiStatus = (payload: APICallStatus): DevicesAction<APICallStatus> => ({
  type: ActionTypes.SET_DAEMONS_API_STATUS,
  payload,
});

export const setDeviceNetworksList = (payload: Network[]): DevicesAction<Network[]> => ({
  type: ActionTypes.SET_DEVICE_NETWORKS_LIST,
  payload,
});

export const setDeviceNetworksListApiStatus = (
  payload: APICallStatus,
): DevicesAction<APICallStatus> => ({
  type: ActionTypes.SET_DEVICE_NETWORKS_LIST_API_STATUS,
  payload,
});

export const setLogDetails = (payload: LogDetail | null): DevicesAction<LogDetail | null> => ({
  type: ActionTypes.SET_LOG_DETAILS,
  payload,
});

export const setLogDetailsApiStatus = (payload: APICallStatus): DevicesAction<APICallStatus> => ({
  type: ActionTypes.SET_LOG_DETAILS_API_STATUS,
  payload,
});

export const setCancelLogApiStatus = (payload: APICallStatus): DevicesAction<APICallStatus> => ({
  type: ActionTypes.SET_CANCEL_LOG_API_STATUS,
  payload,
});

// Thunks

export const getDeviceLogs =
  ({ deviceId, filter, page, sort }: ReqDeviceLogList): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(setLogsApiStatus(APICallStatus.LOADING));
    try {
      const { data } = await getDeviceLogsList({
        deviceId,
        filter,
        page,
        sort,
      });
      dispatch(setLogsData(data));
      dispatch(setLogsApiStatus(APICallStatus.LOADED));
    } catch (err) {
      dispatch(
        setNotificationFail({
          message: 'Failed To Fetch Device Logs',
        }),
      );
      dispatch(setLogsApiStatus(APICallStatus.ERROR));
    }
  };

export const getDevices =
  (): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(setDevicesPending());
    const startTime = new Date();
    try {
      const { data } = await getDevicesApi();
      trackSuccess('Get list', startTime);
      dispatch(setDevicesFulfilled(data));
    } catch (err) {
      const typedError = err as AxiosError;
      trackError('Get list', startTime);
      const message = getErrorMessage(typedError);
      dispatch(setDevicesRejected({ status: typedError?.response?.status, message }));
      dispatch(
        setNotificationFail({
          message: 'Failed To Get Devices',
          description: message,
        }),
      );
    }
  };

export const getOnlineDevices =
  (): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(onlineDevicesPending());
    dispatch(getPackageDeploymentDevicesPending());
    const startTime = new Date();
    try {
      const { data } = await getDevicesApi();
      trackSuccess('Get list', startTime);
      const devices = data.filter(({ status }) => status === DeviceStatus.ONLINE);
      dispatch(onlineDevicesFulfilled(devices));
    } catch (err) {
      trackError('Get list', startTime);
      dispatch(onlineDevicesRejected());
      dispatch(
        setNotificationFail({
          message: 'Failed To Get Devices',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const sshStart = async (
  deviceId: string,
  successCallback: () => void,
  errorCallback: (arg0?: AxiosError) => void,
): Promise<void> => {
  const startTime = new Date();
  try {
    const response = await sshStartApi(deviceId);
    trackSuccess('Start SSH terminal', startTime);
    if (response.data.status === 'success') {
      successCallback();
    } else {
      errorCallback();
    }
  } catch (err) {
    trackError('Start SSH terminal', startTime);
    errorCallback(err as AxiosError);
  }
};

export const downloadDeviceScript =
  (deviceId: string): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    const now: Date = new Date();
    const key: string = now.toISOString();

    dispatch(
      setNotificationInfo({
        message: 'Getting token',
        key,
      }),
    );
    const startTime = new Date();
    try {
      const response = await getTokenApi(deviceId);
      trackSuccess('Get Token Script', startTime);
      if (response.data.status === 'success') {
        dispatch(dismissNotification(key));

        const { token, scriptCommand } = response.data.data;
        const curl = getDeviceCurlScript({ token, scriptCommand });
        dispatch(copyToClipboard(curl, 'Script', false));

        dispatch(
          setNotificationSuccess({
            message: 'Script Has Been Copied to Clipboard and is Valid for 10 Minutes',
            description: 'Execute script on the device',
          }),
        );
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Get Token Script', startTime);
      dispatch(dismissNotification(key));
      dispatch(getTokenRejected());
      dispatch(
        setNotificationFail({
          message: 'Error Getting Token',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const addDevice =
  (deviceDetails: AddDevice): RIOThunkAction =>
  async (dispatch, getState): Promise<void> => {
    dispatch(addDevicePending());
    const startTime = new Date();
    try {
      const response = await getAuthCredsForDeviceApi(deviceDetails);
      trackSuccess('Add', startTime);
      if (response.data.status === 'success') {
        const { token, scriptCommand, deviceId } = response.data.data;
        dispatch(
          addDeviceFulfilled({
            token,
            scriptCommand,
            deviceId,
          }),
        );

        const curl = getDeviceCurlScript({ token, scriptCommand });
        dispatch(copyToClipboard(curl, 'Script', false));

        dispatch(
          setNotificationSuccess({
            message: `Successfully Added Device ${deviceDetails.name}`,
          }),
        );

        const pollInterval = setInterval(() => {
          const { devices } = getState();
          const { error, listApiStatus, list } = devices;

          if (error) {
            clearInterval(pollInterval);
            return;
          }

          const currentDeviceExistsInList = list.some(
            (dev) => dev.name === deviceDetails.name && dev.status !== deviceStatus.DELETED,
          );

          if (currentDeviceExistsInList) {
            clearInterval(pollInterval);
          } else if (!currentDeviceExistsInList && listApiStatus !== APICallStatus.LOADING) {
            dispatch(getDevices());
          }
        }, 3000);

        setTimeout(() => {
          clearInterval(pollInterval);
        }, 180000);
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Add', startTime);
      dispatch(addDeviceRejected());
      dispatch(
        setNotificationFail({
          message: 'Error Adding Device',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const deleteDevice =
  (deviceId: string): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(deleteDevicePending({ deviceId, status: deviceDeleteStatuses.pending }));
    const startTime = new Date();
    try {
      await deleteDeviceApi(deviceId);
      trackSuccess('Delete', startTime);
      dispatch(deleteDeviceFulfilled({ deviceId, status: deviceDeleteStatuses.deleted }));
      dispatch(copyToClipboard('sudo rapyuta-agent-uninstall', 'command', false));
      dispatch(
        setNotificationSuccess({
          message: 'Device Successfully Deleted',
          description:
            'To do complete uninstall, please execute the command copied to clipboard on the device',
        }),
      );
      dispatch(getDevices());
    } catch (err) {
      trackError('Delete', startTime);
      const error = getDeviceDeleteErrorMessage(err);
      dispatch(deleteDeviceRejected({ deviceId, status: deviceDeleteStatuses.initial }));
      if (error?.deployments) {
        dispatch(deleteDeviceInUseWarn({ error, deviceId }));
      } else {
        dispatch(
          setNotificationFail({
            message: 'Failed To Delete the Device',
            description: getErrorMessage(err as AxiosError),
          }),
        );
      }
    }
  };

export const getLogsDetails =
  (payload: ReqDownloadDeviceLog, refreshLogs: () => void): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(setLogDetailsApiStatus(APICallStatus.LOADING));
    try {
      const {
        data: { data },
      } = await getDeviceLogDetailsApi(payload);
      dispatch(setLogDetails(data));
      dispatch(setLogDetailsApiStatus(APICallStatus.LOADED));
      refreshLogs();
    } catch (error) {
      dispatch(setLogDetailsApiStatus(APICallStatus.ERROR));
      const err = error as AxiosError;
      const errMessage = err.response?.data.response.error || 'Error deleting log';
      const errorPayload: NotificationPayload = {
        message: 'Failed To Get File Upload Details',
        description: errMessage,
      };
      dispatch(setNotificationFail(errorPayload));
    }
  };

export const deleteDeviceLog =
  (payload: ReqDownloadDeviceLog, refreshLogs: () => void): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    try {
      await deleteDeviceLogApi(payload);
      dispatch(
        setNotificationSuccess({
          message: 'Log Deleted Successfully',
        }),
      );
      refreshLogs();
    } catch (error) {
      const err = error as AxiosError;
      const errMessage = err.response?.data.response.error || 'Error deleting log';
      const errorPayload: NotificationPayload = {
        message: 'Failed To Delete Log',
        description: errMessage,
      };
      dispatch(setNotificationFail(errorPayload));
    }
  };

export const downloadDeviceLog =
  (payload: ReqDownloadDeviceLog): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    try {
      const {
        data: {
          data: { signedUrl },
        },
      } = await downloadDeviceLogApi(payload);
      window.open(signedUrl);
    } catch (error) {
      dispatch(
        setNotificationFail({
          message: 'Failed To Download Log',
          description: getMessageFromError(error as AxiosError),
        }),
      );
    }
  };

export const cancelUploadLog =
  (payload: ReqDownloadDeviceLog, refreshLogs: () => void): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setCancelLogApiStatus(APICallStatus.LOADING));
      await cancelDeviceLogUploadApi(payload);
      dispatch(
        setNotificationSuccess({
          message: 'Upload Cancelled Successfully',
        }),
      );
      dispatch(getLogsDetails(payload, refreshLogs));
      refreshLogs();
      dispatch(setCancelLogApiStatus(APICallStatus.LOADED));
    } catch (error) {
      dispatch(setCancelLogApiStatus(APICallStatus.ERROR));
      const err = error as AxiosError;
      const errMessage = err.response?.data.response.error || 'Error cancelling upload';
      const errorPayload: NotificationPayload = {
        message: 'Failed To Cancel Upload',
        description: errMessage,
      };
      dispatch(setNotificationFail(errorPayload));
    }
  };

export const openModal =
  () =>
  (dispatch: Dispatch): void => {
    trackEvent('DeviceList', 'Open Add Device Modal');
    dispatch(addModalOpenAction());
  };
export const closeModal =
  (): RIOThunkAction =>
  (dispatch): void => {
    trackEvent('DeviceList', 'Close Add Device Modal');
    dispatch(addModalCloseAction());
  };

export const getUname =
  (deviceId: string): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(getUnamePending(deviceId));
    const startTime = new Date();
    try {
      const response = await getUnameApi(deviceId);
      trackSuccess('Get Uname', startTime);
      if (response.data.status === 'success') {
        dispatch(getUnameFulfilled(response.data.data[deviceId]));
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Get Uname', startTime);
      dispatch(getUnameRejected(deviceId));
      dispatch(
        setNotificationFail({
          message: 'Failed To Get Device Uname',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const getDeviceDetails =
  (deviceId: string, project = ''): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(getDeviceDetailsPending(deviceId));
    const startTime = new Date();

    try {
      const response = await getDeviceDetailsApi(
        deviceId,
        project ? { headers: { project } } : undefined,
      );
      trackSuccess('Get Details', startTime);
      if (response.data.status === 'success') {
        dispatch(getDeviceDetailsFulfilled(response.data.data));
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Get Details', startTime);
      const data = {
        deviceId,
        error: {
          status: (err as AxiosError)?.response?.status,
          message: getDeviceDetailsErrorMessage(err),
        },
      };
      dispatch(getDeviceDetailsRejected(data));
    }
  };

export const getDaemonsData =
  (deviceId: string): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(setDaemonsApiStatus(APICallStatus.LOADING));
    try {
      const response = await getDaemonsApi(deviceId);

      dispatch(setDaemons(response.data));
      dispatch(setDaemonsApiStatus(APICallStatus.LOADED));
    } catch (err) {
      dispatch(setDaemonsApiStatus(APICallStatus.ERROR));
      dispatch(
        setNotificationFail({
          message: 'Failed To Get Daemons',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const saveVPNDetails =
  (deviceId: string, deviceVPNData: SaveVPNDetailsPayload): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    try {
      const response = await saveVPNDetailsApi(deviceId, deviceVPNData);
      if (response.data.status === 'success') {
        dispatch(getDaemonsData(deviceId));
        dispatch(
          setNotificationSuccess({
            message: 'Device VPN Details Saved',
          }),
        );
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      dispatch(
        setNotificationFail({
          message: 'Error Saving Device VPN Details',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const executeCommand =
  (data: ReqCmd): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(execCmdPending(data));
    const startTime = new Date();
    try {
      const response = await executeCommandApi(data);
      trackSuccess('Execute command', startTime);
      if (response.data.status === 'success') {
        dispatch(execCmdFulfilled(response.data.data));
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Execute command', startTime);
      dispatch(execCmdRejected());
      dispatch(
        setNotificationFail({
          message: 'Failed To Execute Command',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const updateName =
  (deviceId: string, deviceName: string): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(updateNamePending(deviceId));
    const startTime = new Date();
    try {
      const response = await updateNameApi(deviceId, deviceName);
      trackSuccess('Update name', startTime);
      if (response.data.status === 'success') {
        dispatch(updateNameFulfilled(deviceName));
        dispatch(
          setNotificationSuccess({
            message: 'Successfully Updated Device Name',
          }),
        );
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Update name', startTime);
      dispatch(updateNameRejected());
      dispatch(
        setNotificationFail({
          message: 'Failed To Update Device Name',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const updateDescription =
  (deviceId: string, description: string): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(updateDescPending(deviceId));
    const startTime = new Date();
    try {
      const response = await updateDeviceDescriptionApi(deviceId, description);
      trackSuccess('Update description', startTime);
      if (response.data.status === 'success') {
        dispatch(updateDescFulfilled(description));
        dispatch(
          setNotificationSuccess({
            message: 'Updated Device Description Successfully',
          }),
        );
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Update description', startTime);
      dispatch(updateDescRejected());
      dispatch(
        setNotificationFail({
          message: 'Failed To Update Device Description',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const updateNameAndDescription =
  (data: UpdateNameAndDescriptionPayload): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    try {
      const response = await updateNameAndDescriptionApi(data);

      if (response.data.status === 'success') {
        dispatch(
          setNotificationSuccess({
            message: 'Updated Device Name And Description Successfully',
          }),
        );
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      dispatch(
        setNotificationFail({
          message: 'Failed To Update Device Name And Description',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const dirtyDeviceLabelKey = (label: Label): DevicesAction<Label> =>
  dirtyLabelKey({ ...label, isDirty: true });

export const dirtyDeviceLabelValue = (label: Label): DevicesAction<Label> =>
  dirtyLabelValue({ ...label, isDirty: true });

export const deviceLabelDelete =
  (label: Label): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    const { id, key } = label;
    dispatch(deleteLabelPending({ ...label, deletePending: true }));
    const startTime = new Date();
    try {
      const response = await deleteDeviceLabelApi(id);
      trackSuccess('Delete label', startTime);
      if (response.data.status === 'success') {
        dispatch(deleteLabelFulfilled(label));
        dispatch(
          setNotificationSuccess({
            message: 'Label Deleted',
            description: `Label ${key} successfully deleted`,
          }),
        );
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Delete label', startTime);
      dispatch(deleteLabelRejected({ ...label, deletePending: false }));
      dispatch(
        setNotificationFail({
          message: 'Failed To Delete Label',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const updateDeviceLabel =
  (label: Label): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    const { id, key, value } = label;
    dispatch(updateLabelPending({ ...label, updatePending: true }));
    const startTime = new Date();
    try {
      const response = await updateDeviceLabelApi(id, key, value);
      trackSuccess('Update label', startTime);
      if (response.data.status === 'success') {
        dispatch(updateLabelFulfilled(label));
        dispatch(
          setNotificationSuccess({
            message: 'Label Updated',
            description: `Label ${key} successfully updated`,
          }),
        );
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Update label', startTime);
      dispatch(updateLabelRejected({ ...label, updatePending: false }));
      dispatch(
        setNotificationFail({
          message: 'Failed To Update Label',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const addDeviceLabel =
  (key: string, value: string, deviceId: string): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(addLabelPending(deviceId));
    const startTime = new Date();
    try {
      const response = await addDeviceLabelApi(deviceId, key, value);
      trackSuccess('Add label', startTime);
      if (response.data.status === 'success') {
        dispatch(
          addLabelFulfilled({
            key,
            value,
            id: response.data.data[0].id,
          }),
        );
        dispatch(
          setNotificationSuccess({
            message: 'Label Added',
            description: `Label ${key} successfully added`,
          }),
        );
      } else {
        throw new Error(response?.data?.error);
      }
    } catch (err) {
      trackError('Add label', startTime);
      dispatch(addLabelRejected());
      dispatch(
        setNotificationFail({
          message: 'Failed To Add Label',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const migrateDevice =
  (payload: MigrateDevicePayload): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(setMigrateDeviceApiStatus(APICallStatus.LOADING));
    dispatch(
      setNotificationInfo({
        message: 'Initiated Device Migration',
      }),
    );
    try {
      await migrateDeviceApi(payload);
      dispatch(
        setNotificationSuccess({
          message: 'Successfully Migrated Device',
        }),
      );
      dispatch(setMigrateDeviceApiStatus(APICallStatus.LOADED));
      dispatch(changeSelectedProject(payload.projectId));
      const url = payload.isDetailsPage ? `/devices/${payload.deviceId}` : '/devices/';
      history.push(url);
    } catch (err) {
      dispatch(
        setNotificationFail({
          message: 'Failed To Migrate Device',
          description: getErrorMessage(err as AxiosError),
        }),
      );
      dispatch(setMigrateDeviceApiStatus(APICallStatus.LOADED));
    }
  };

export const getDeviceDeployments =
  (payload: DeviceDeploymentsPayload): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(setDeviceDeploymentListAPICallStatus(APICallStatus.LOADING));
    try {
      const response = await getDeviceDeploymentsListApi(payload.phases, payload.deviceName);
      dispatch(setDeviceDeploymentList(response));
      dispatch(setDeviceDeploymentListAPICallStatus(APICallStatus.LOADED));
    } catch (err) {
      dispatch(setDeviceDeploymentListAPICallStatus(APICallStatus.ERROR));
      dispatch(
        setNotificationFail({
          message: 'Failed To Get Deployments',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };

export const getDeviceNetworks =
  (deviceName: string): RIOThunkAction =>
  async (dispatch): Promise<void> => {
    dispatch(setDeviceNetworksListApiStatus(APICallStatus.LOADING));
    try {
      const response = await getNetworkListApi([], deviceName);
      dispatch(setDeviceNetworksList(response));
      dispatch(setDeviceNetworksListApiStatus(APICallStatus.LOADED));
    } catch (err) {
      dispatch(setDeviceNetworksListApiStatus(APICallStatus.ERROR));
      dispatch(
        setNotificationFail({
          message: 'Failed To Get Networks',
          description: getErrorMessage(err as AxiosError),
        }),
      );
    }
  };
