import { ORG_ROOT_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { Organization, UpdateOrgPayload } from './types';

export const getOrganizationDetails = (orgGUID: string): Promise<Organization> =>
  authAxios(
    {
      method: 'GET',
      url: `${ORG_ROOT_URL}/${orgGUID}/`,
      headers: {
        organizationguid: orgGUID,
      },
    },
    false,
  ).then((response) => response.data);

export const updateOrganization = (data: UpdateOrgPayload): Promise<Organization> =>
  authAxios(
    {
      method: 'PUT',
      url: `${ORG_ROOT_URL}/${data.metadata.guid}/`,
      data,
    },
    false,
    true,
  ).then((response) => response.data);
