import { deviceToCLIDeviceSpec, getManifest } from 'Models/cliManifest/dataFormatters';
import { ManifestKinds } from 'Models/cliManifest/types';
import { SortDirection } from 'Models/common/types';
import { ConfigVars, DeviceRuntimes, DeviceSortOption, DeviceStatus } from 'Models/devices/types';
import { DEVICE_MANAGER_BOOTSTRAP_URL } from 'Shared/config';
import { getErrorMessage } from 'Shared/utils/core';
import { sortByCb, union } from 'Shared/utils/core/array';
import storeUtilAdapterInst from 'Shared/utils/storeUtilsAdapter';

import { deviceNameRegex } from './constants';

export const deviceStatus = {
  NEW: 'NEW',
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  REGISTERED: 'REGISTERED',
  INITIALIZING: 'INITIALIZING',
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  DELETED: 'DELETED',
  REJECTED: 'REJECTED',
};

export const statusIconColors = {
  online: '#3e991c',
  initializing: '#f9C351',
  offline: '#DDDDDD',
  registered: '#0074ff',
  rejected: '#ff3e3c',
  failed: '#ff3e3c',
};

export const deviceStatusPriority = {
  ONLINE: 0,
  FAILED: 1,
  OFFLINE: 2,
  INITIALIZING: 3,
  ERROR: 4,
  REGISTERED: 5,
  REJECTED: 6,
  NEW: 7,
  DELETED: 8,
};

export const deviceDeleteStatuses = {
  pending: 'p',
  initial: 'i',
  deleted: 'd',
};

export const sortOptions = {
  name: 'Name',
  lastOnline: 'Last online time',
  registrationTime: 'Registration time',
  status: 'Status',
};
export const filterOptions = ['ALL', ...Object.keys(DeviceStatus)];

export const searchDeviceItem = (deviceItem, text) => {
  // Usual properties
  if (
    [
      storeUtilAdapterInst.getUsernameFromGUID(deviceItem.createdBy),
      deviceItem.description || '',
      deviceItem.uuid,
      deviceItem.name,
    ].some((item) => item.toLocaleLowerCase().includes(text.toLowerCase()))
  ) {
    return true;
  }
  // Label values
  if (
    (deviceItem.labels || [])
      .map((lItem) => lItem.value)
      .some((item) => item.toLocaleLowerCase().includes(text.toLowerCase()))
  ) {
    return true;
  }
  // Label keys with value true
  return (deviceItem.labels || []).some((item) =>
    item.value === 'true' ? item.key.toLocaleLowerCase().includes(text.toLowerCase()) : false,
  );
};

export const sortDevices = (filteredDevices, sortBy, sortDirection) => {
  let sortedDevices;
  switch (sortBy) {
    case DeviceSortOption.NAME:
      sortedDevices = sortByCb(filteredDevices, (d) => d.name.toLowerCase());
      if (sortDirection === SortDirection.DESCEND) {
        sortedDevices = sortedDevices.reverse();
      }
      break;
    case DeviceSortOption.REGISTRATION_TIME:
    case DeviceSortOption.LAST_ONLINE:
      sortedDevices = filteredDevices.reduce(
        (prev, device) =>
          device[sortBy]
            ? { ...prev, toSort: [...prev.toSort, device] }
            : { ...prev, toNotSort: [...prev.toNotSort, device] },
        { toSort: [], toNotSort: [] },
      );
      if (sortDirection === SortDirection.ASCEND) {
        sortedDevices = union(
          sortByCb(sortedDevices.toSort, (d) => d[sortBy]).reverse(),
          sortedDevices.toNotSort,
        );
      } else {
        sortedDevices = union(
          sortByCb(sortedDevices.toSort, (d) => d[sortBy]),
          sortedDevices.toNotSort,
        );
      }
      break;
    case DeviceSortOption.STATUS:
      sortedDevices = sortByCb(filteredDevices, (d) => deviceStatusPriority[d.status]);
      if (sortDirection === SortDirection.DESCEND) {
        sortedDevices.reverse();
      }
      break;
    default:
      sortedDevices = filteredDevices;
      break;
  }
  return sortedDevices;
};

export const getDeviceDetailsErrorMessage = (error) => {
  const status = error?.response?.status;
  if (status === 404) {
    return '404 - Device could not be found';
  }
  return getErrorMessage(error);
};

export const getDeviceDeleteErrorMessage = (error) => {
  const status = error.response?.status ?? 500;
  let deployments = null;
  let message =
    error.response.data?.response?.error || error.response.data?.response || error.message;
  switch (status) {
    case 404:
      message = '404 - Device could not be found';
      break;
    case 400:
      deployments = error.response.data?.response?.error;
      break;
    default:
      break;
  }

  return {
    status,
    message,
    deployments,
  };
};

export const validationMessages = {
  emptyDeviceName: 'Device name cannot be empty',
  improperDeviceName:
    'Device name must consist of alphabetic characters, space, digits, - or _ and must start with an alphabet',
  emptyMountPath: 'Rosbag mount path cannot be empty',
  improperMountPath: 'Add a suitable Rosbag mount path',
};

export const PAGE_SIZES = [10, 20, 30, 40];

export const validateDeviceName = (deviceName) => {
  if (!deviceName) {
    return null;
  }
  if (!deviceName.trim()) {
    return validationMessages.emptyDeviceName;
  }
  if (!deviceName.match(deviceNameRegex)) {
    return validationMessages.improperDeviceName;
  }
  return null;
};

export const validateRosMountPath = (mountPath) => {
  if (!mountPath) {
    return validationMessages.emptyMountPath;
  }
  if (!mountPath.trim()) {
    return validationMessages.emptyMountPath;
  }
  return null;
};

export const isOlderDeviceForSecurityVulnerability = (lastOnlineTime) => {
  if (!lastOnlineTime) {
    return false; // we don't care if the device was never online
  }
  return new Date(lastOnlineTime) < new Date('2020-05-04T06:45:00');
};

export const isAMD64Device = (deviceDetails) => deviceDetails.uname.includes('x86_64');

export const hasDockerRuntime = (configVariables) =>
  configVariables?.some(
    ({ key, value }) =>
      (key === ConfigVars.DOCKER && value === 'True') ||
      (key === ConfigVars.RUNTIME && value === DeviceRuntimes.DOCKER_COMPOSE),
  );

export const hasPreInstalledRuntime = (configVariables) =>
  configVariables?.some(
    ({ key, value }) =>
      (key === ConfigVars.PRE_INSTALLED && value === 'True') ||
      (key === ConfigVars.RUNTIME && value === DeviceRuntimes.PRE_INSTALLED),
  );

export const isDockerCompose = (deviceDetails) => {
  const configVars = deviceDetails.configVariables || [];
  const result = hasDockerRuntime(configVars);

  return result;
};

export const UPLOAD_RATE_UNITS = {
  b: 'Bytes/s',
  kb: 'KB/s',
  mb: 'MB/s',
};

export const BYTE_SIZE = 1024;

export const FILENAME_LENGTH = 956;

export const convertInputToBytes = (bandwidth = 0, uploadUnits) => {
  if (!bandwidth) {
    return null;
  }
  const intBandwidth = parseInt(bandwidth, 10);
  switch (uploadUnits) {
    case UPLOAD_RATE_UNITS.kb:
      return intBandwidth * BYTE_SIZE;
    case UPLOAD_RATE_UNITS.mb:
      return intBandwidth * BYTE_SIZE * BYTE_SIZE;
    default:
      return intBandwidth;
  }
};

export const fetchDeploymentName = ({ deploymentID, deploymentsList }) => {
  const { name } = deploymentsList?.find((d) => d.deploymentId === deploymentID) ?? {};
  return name;
};
const filePathRegx = /^(\/)?([^/\0]+(\/)?)+$/;

export const BATCH_CONFIG_VAR_MAP = {
  BATCH_LOG_DIR: {
    key: 'batch_log_dir',
    label: 'Backup Directory',
    tooltip: 'directory path where backup files will be kept',
    errorMsg: 'directory path is invalid',
    validation: (value) => value !== '' && filePathRegx.test(value),
  },
  BATCH_LOG_ROTATION_ARCHIVES: {
    key: 'batch_log_rotation_archives',
    label: 'Rotated Archives limit',
    tooltip: 'Maximum number of rotated archives to keep, any older logs are deleted',
  },
  BATCH_LOG_ROTATION_SIZE_MB: {
    key: 'batch_log_rotation_size_mb',
    label: 'Rotation Size',
    tooltip: 'The logfile will be rotated when it becomes larger than the specified size',
    errorMsg: 'allowed values are between [1 - 2048]',
    validation: (value) => {
      const parsedVal = parseInt(value, 10);
      return value && parsedVal <= 2048 && parsedVal > 0;
    },
  },
  ENABLE_BATCH_LOGGING: { key: 'enable_batch_logging', label: 'Enable Logging' },
  BATCH_LOG_ROTATION_INTERVAL_MINUTES: {
    key: 'batch_log_rotation_interval_minutes',
    label: 'Rotation Interval',
    tooltip: 'The file will be rotated after the time interval specified',
  },
};

export const convertToUTCString = (apiDate) => (apiDate ? `${apiDate.slice(0, -3)}Z` : null);

export const DEVICE_NAME_EDIT_ALLOWED_STATUSES = [
  deviceStatus.ONLINE,
  deviceStatus.FAILED,
  deviceStatus.INITIALIZING,
];

export const getDeviceCurlScript = ({ token, scriptCommand }) =>
  `curl -O -H 'Authorization: Bearer ${token}' ${DEVICE_MANAGER_BOOTSTRAP_URL}start && ${scriptCommand}`;

export const getDeviceManifest = ({ device, projectGUID }) => {
  const deviceSpec = deviceToCLIDeviceSpec(device);
  const manifest = getManifest(
    ManifestKinds.DEVICE,
    { name: device.name, project: projectGUID },
    deviceSpec,
  );

  return manifest;
};
