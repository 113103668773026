import { APICallStatus, RIOError } from 'Models/common/types';
import {
  AddDeviceResponse,
  DaemonsApiResponse,
  DetailsError,
  Device,
  DeviceDetails,
  DevicesAction,
  DevicesActionPayload,
  DevicesState,
  DeviceStatus,
  Label,
  LogDetail,
  Progress,
  ResDeviceLog,
  TerminalData,
  UpdateStatus,
} from 'Models/devices/types';
import { deviceDeleteStatuses, deviceStatus } from 'Root/devices/utils';
import { Network } from 'Root/models/networks/types';
import { PackageDeploymentList } from 'Root/models/packages/types';

import ActionTypes from './actionTypes';

export const defaultInstallationMeta: Progress = {
  stages: [],
  totalStages: 0,
};

export const initialDeviceDetailsData: DeviceDetails = {
  deviceVersion: '',
  deployments: [],
  host: '',
  lsbDistribDescription: '',
  saltVersion: '',
  username: '',
  ipInterfaces: {},
  status: DeviceStatus.OFFLINE,
  uuid: '',
  deleteStatus: '',
  registrationTime: '',
  lastOnline: '',
  name: '',
  createdBy: '',
  description: '',
  labels: [],
  configVariables: [],
  installationMetadata: defaultInstallationMeta,
  macAddresses: {},
  pythonVersion: '',
};

export const initialState: DevicesState = {
  selectedDeviceID: null,
  list: [],
  listApiStatus: APICallStatus.INITIAL,
  error: null,
  addDeviceApiStatus: APICallStatus.INITIAL,
  modalOpen: false,
  addDeviceToken: '',
  scriptCommand: '',

  // DEVICE SUBSCRIPTIONS
  topicsApiStatus: APICallStatus.INITIAL,
  masterUp: false,

  // DEVICE DETAILS
  detailsApiStatus: APICallStatus.INITIAL,
  deviceId: '',
  deviceDetails: initialDeviceDetailsData,
  uname: '',
  unameFailed: false,
  addLabelKey: '',
  addLabelValue: '',
  addLabelPending: false,
  sshSocket: null,
  nameEditMode: false,
  editedName: '',
  descriptionEditMode: false,
  editedDescription: '',
  runningDeploymentsPayload: null,

  // DEVICE TERMINAL
  terminalApiStatus: APICallStatus.INITIAL,
  terminalData: [],

  // MANAGE LOGS
  logs: [],
  logsTotal: 0,
  logsApiStatus: APICallStatus.INITIAL,

  // MIGRATE DEVICE
  migrateDeviceApiStatus: APICallStatus.INITIAL,

  // DAEMONS
  daemons: null,
  daemonsApiStatus: APICallStatus.INITIAL,

  // DEVICE DEPLOYMENTS
  deviceDeploymentList: [],
  deviceDeploymentListAPICallStatus: APICallStatus.INITIAL,

  // DEVICE NETWORKS
  deviceNetworksList: [],
  deviceNetworksListAPICallStatus: APICallStatus.INITIAL,

  // DEVICE FILES
  logDetails: null,
  logDetailsApiStatus: APICallStatus.INITIAL,
  cancelLogApiStatus: APICallStatus.INITIAL,
};

const devicesReducer = (
  state: DevicesState = initialState,
  action: DevicesAction<DevicesActionPayload>,
): DevicesState => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_DEVICE_ID:
      return { ...state, selectedDeviceID: action.payload as string };

    case ActionTypes.SET_LOGS_API_STATUS:
      return { ...state, logsApiStatus: action.payload as APICallStatus };

    case ActionTypes.SET_LOGS_DATA: {
      const {
        data,
        meta: { total },
      } = action.payload as ResDeviceLog;
      return {
        ...state,
        logs: data,
        logsTotal: total,
      };
    }
    case ActionTypes.DEVICE_ADD_PENDING:
      return { ...state, addDeviceApiStatus: APICallStatus.LOADING };

    case ActionTypes.DEVICE_ADD_FULFILLED: {
      const { token, scriptCommand } = action.payload as AddDeviceResponse;
      return {
        ...state,
        addDeviceApiStatus: APICallStatus.LOADED,
        scriptCommand,
        modalOpen: false,
        addDeviceToken: token,
      };
    }

    case ActionTypes.DEVICE_ADD_REJECTED:
      return {
        ...state,
        addDeviceApiStatus: APICallStatus.ERROR,
      };

    case ActionTypes.DEVICE_DELETE_PENDING:
    case ActionTypes.DEVICE_DELETE_REJECTED:
    case ActionTypes.DEVICE_DELETE_FULFILLED: {
      const { deviceId, status } = action.payload as UpdateStatus;
      const list = state.list.map((dev) => {
        const temp = { ...dev };
        if (status === deviceDeleteStatuses.deleted) {
          temp.status = temp.uuid === deviceId ? deviceStatus.DELETED : temp.status;
        }
        temp.deleteStatus = temp.uuid === deviceId ? status : temp.deleteStatus;
        return temp;
      });
      return { ...state, list };
    }

    case ActionTypes.ADD_DEVICE_DELETE_IN_USE_WARNING:
      return {
        ...state,
        runningDeploymentsPayload: action.payload as DetailsError,
      };

    case ActionTypes.REMOVE_DEVICE_DELETE_IN_USE_WARNING:
      return {
        ...state,
        runningDeploymentsPayload: null,
      };

    case ActionTypes.GET_DEVICE_TOKEN_MODAL_FULFILLED:
      return {
        ...state,
        modalOpen: true,
        addDeviceToken: action.payload as string,
      };

    case ActionTypes.GET_DEVICE_TOKEN_FULFILLED:
      return {
        ...state,
        addDeviceToken: action.payload as string,
      };

    case ActionTypes.ADD_DEVICE_MODAL_OPEN:
      return { ...state, modalOpen: true, addDeviceToken: '', scriptCommand: '' };

    case ActionTypes.ADD_DEVICE_MODAL_CLOSE:
      return { ...state, modalOpen: false, addDeviceApiStatus: APICallStatus.INITIAL };

    case ActionTypes.DEVICES_GET_PENDING:
      return { ...state, listApiStatus: APICallStatus.LOADING, error: null };

    case ActionTypes.DEVICES_GET_FULFILLED:
      return {
        ...state,
        listApiStatus: APICallStatus.LOADED,
        error: null,
        list: action.payload as Device[],
      };

    case ActionTypes.DEVICES_GET_REJECTED:
      return {
        ...state,
        list: [],
        listApiStatus: APICallStatus.ERROR,
        error: action.payload as RIOError,
      };

    // DEVICE SUBSCRIPTIONS
    case ActionTypes.DEVICE_PAGE_SET_ID: {
      if (state.deviceId !== action.payload) {
        return { ...initialState, deviceId: action.payload as string };
      }
      return state;
    }

    // DEVICE DETAILS
    case ActionTypes.TOGGLE_DEVICE_NAME_EDIT_MODE:
      return {
        ...state,
        nameEditMode: !state.nameEditMode,
        editedName: state.deviceDetails.name || '',
      };

    case ActionTypes.DIRTY_DEVICE_NAME:
      return {
        ...state,
        editedName: action.payload as string,
      };

    case ActionTypes.DEVICE_NAME_UPDATE_FULFILLED:
      return {
        ...state,
        nameEditMode: false,
        deviceDetails: { ...state.deviceDetails, name: action.payload as string },
      };

    case ActionTypes.TOGGLE_DEVICE_DESCRIPTION_EDIT_MODE:
      return {
        ...state,
        descriptionEditMode: !state.descriptionEditMode,
        editedDescription: state.deviceDetails?.description || '',
      };

    case ActionTypes.DIRTY_DEVICE_DESCRIPTION:
      return {
        ...state,
        editedDescription: action.payload as string,
      };

    case ActionTypes.DEVICE_DESCRIPTION_UPDATE_FULFILLED:
      return {
        ...state,
        descriptionEditMode: false,
        deviceDetails: {
          ...state.deviceDetails,
          description: action.payload as string,
        },
      };

    case ActionTypes.DEVICE_DETAILS_GET_PENDING:
      return { ...state, detailsApiStatus: APICallStatus.LOADING };

    case ActionTypes.DEVICE_DETAILS_GET_FULFILLED: {
      const deviceDetails = action.payload as DeviceDetails;
      return {
        ...state,
        error: null,
        detailsApiStatus: APICallStatus.LOADED,
        deviceDetails,
      };
    }

    case ActionTypes.DEVICE_DETAILS_GET_REJECTED: {
      const data = action.payload as DetailsError;
      if (state.deviceId === data.deviceId) {
        return {
          ...state,
          detailsApiStatus: APICallStatus.ERROR,
          error: data.error,
        };
      }
      return state;
    }

    case ActionTypes.DEVICE_LABEL_DELETE_FULFILLED: {
      const temp = { ...state.deviceDetails };
      const { id } = action.payload as Label;
      temp.labels = temp.labels.filter((o) => o.id !== id);
      return { ...state, deviceDetails: temp };
    }

    case ActionTypes.DEVICE_DIRTY_LABEL_KEY:
    case ActionTypes.DEVICE_DIRTY_LABEL_VALUE:
    case ActionTypes.DEVICE_LABEL_DELETE_PENDING:
    case ActionTypes.DEVICE_LABEL_DELETE_REJECTED:
    case ActionTypes.DEVICE_LABEL_UPDATE_PENDING:
    case ActionTypes.DEVICE_LABEL_UPDATE_REJECTED:
    case ActionTypes.DEVICE_LABEL_UPDATE_FULFILLED: {
      const temp = { ...state.deviceDetails };
      const label = action.payload as Label;
      temp.labels = temp.labels.map((o) => (o.id === label.id ? label : o));
      return { ...state, deviceDetails: temp };
    }

    case ActionTypes.DEVICE_DIRTY_ADD_LABEL_KEY:
      return {
        ...state,
        addLabelKey: action.payload as string,
      };

    case ActionTypes.DEVICE_DIRTY_ADD_LABEL_VALUE:
      return {
        ...state,
        addLabelValue: action.payload as string,
      };

    case ActionTypes.DEVICE_ADD_LABEL_PENDING:
      return {
        ...state,
        addLabelPending: true,
      };

    case ActionTypes.DEVICE_ADD_LABEL_FULFILLED:
      return {
        ...state,
        addLabelPending: false,
        addLabelKey: '',
        addLabelValue: '',
        deviceDetails: {
          ...state.deviceDetails,
          labels: [...state.deviceDetails.labels, action.payload as Label],
        },
      };

    case ActionTypes.DEVICE_ADD_LABEL_REJECTED:
      return {
        ...state,
        addLabelPending: false,
      };

    case ActionTypes.DEVICE_UNAME_GET_FULFILLED:
      return {
        ...state,
        unameFailed: false,
        uname: action.payload as string,
      };

    case ActionTypes.DEVICE_UNAME_GET_REJECTED:
      return {
        ...state,
        unameFailed: true,
      };

    // DEVICE TERMINAL
    case ActionTypes.DEVICE_COMMAND_POST_PENDING:
      return { ...state, terminalApiStatus: APICallStatus.LOADING };

    case ActionTypes.DEVICE_COMMAND_POST_FULFILLED: {
      const { deviceId } = state;
      const data = action.payload as TerminalData;
      const commandsResponse = [...state.terminalData].concat(data[deviceId] || null);
      return {
        ...state,
        terminalData: commandsResponse,
        terminalApiStatus: APICallStatus.LOADED,
      };
    }

    case ActionTypes.DEVICE_COMMAND_POST_REJECTED:
      if (state.deviceId === action.payload) {
        return {
          ...state,
          terminalApiStatus: APICallStatus.ERROR,
        };
      }
      return state;

    case ActionTypes.SET_MIGRATE_DEVICE_API_STATUS:
      return {
        ...state,
        migrateDeviceApiStatus: action.payload as APICallStatus,
      };
    case ActionTypes.SET_DAEMONS:
      return {
        ...state,
        daemons: action.payload as DaemonsApiResponse,
      };

    case ActionTypes.SET_DAEMONS_API_STATUS:
      return {
        ...state,
        daemonsApiStatus: action.payload as APICallStatus,
      };
    case ActionTypes.SET_DEVICE_DEPLOYMENTS_LIST:
      return {
        ...state,
        deviceDeploymentList: action.payload as PackageDeploymentList[],
      };
    case ActionTypes.SET_DEVICE_DEPLOYMENTS_LIST_API_STATUS:
      return {
        ...state,
        deviceDeploymentListAPICallStatus: action.payload as APICallStatus,
      };
    case ActionTypes.SET_DEVICE_NETWORKS_LIST:
      return {
        ...state,
        deviceNetworksList: action.payload as Network[],
      };
    case ActionTypes.SET_DEVICE_NETWORKS_LIST_API_STATUS:
      return {
        ...state,
        deviceNetworksListAPICallStatus: action.payload as APICallStatus,
      };
    case ActionTypes.SET_LOG_DETAILS:
      return {
        ...state,
        logDetails: action.payload as LogDetail,
      };
    case ActionTypes.SET_LOG_DETAILS_API_STATUS:
      return {
        ...state,
        logDetailsApiStatus: action.payload as APICallStatus,
      };
    case ActionTypes.SET_CANCEL_LOG_API_STATUS:
      return {
        ...state,
        cancelLogApiStatus: action.payload as APICallStatus,
      };
    default:
      return state;
  }
};

export default devicesReducer;
