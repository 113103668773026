import { ErrorMap, TableQuery } from 'Models/common/types';
import { DeviceSortOption } from 'Models/devices/types';

export const deviceNameRegex = /^[a-zA-Z][a-z A-Z0-9-_]*$/;

export const qos = {
  low: 0,
  medium: 1,
  high: 2,
};

export const INIT_QUERY_ROSBAG_TABLE: TableQuery = {
  pagination: {
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    pageSizeOptions: ['10', '20', '30', '40'],
    showQuickJumper: true,
    showSizeChanger: true,
  },
  filters: {},
  sort: '',
};

export const OLD_SALT_VERSIONS = ['2017.7.0', '2017.7.0-n/a-39043d1744'];

export const DEVICE_DETAILS_PATH = `/devices/:device_id`;

export const DeviceSortLabel = {
  [DeviceSortOption.LAST_ONLINE]: 'Last Online Time',
  [DeviceSortOption.NAME]: 'Name',
  [DeviceSortOption.REGISTRATION_TIME]: 'Registration Time',
  [DeviceSortOption.STATUS]: 'Status',
};

export const DeviceError: ErrorMap = {
  DEV_E100: {
    description: 'Internal error',
    actions: ['Please contact rapyuta.io support.'],
  },
  DEV_E101: {
    description: 'Failed to download the internal artifact',
    actions: [
      'Ensure that device has an active internet connection',
      'Ensure that there is adequate disk space on device',
    ],
  },
  DEV_E102: {
    description: 'Failed to pull the internal docker image',
    actions: [
      'Ensure that device has an active internet connection',
      'Ensure that there is adequate disk space on device',
      'Ensure that the docker version on the device is one of the following: (1) docker-ce: 5:20.10.7~3-0~ubuntu-xenial for Ubuntu 16.04 (2) docker-ce: 5:20.10.7~3-0~ubuntu-bionic for Ubuntu 18.04 (3) docker-ce: 5:20.10.7~3-0~ubuntu-focal for Ubuntu 20.04. If not, uninstall it and re-onboard the device again.',
    ],
  },
  DEV_E103: {
    description: 'Failed to install the pip package',
    actions: [
      'Ensure that device has an active internet connection',
      'Ensure that there is adequate disk space on device',
      'Ensure that the version of Python on device is Python>=2.7.8,<3.9. If not, uninstall it and re-onboard the device again.',
      'Try installing the package manually by executing source /opt/rapyuta/venv/bin/activate && pip install <python-package> and see if it fails. If it doesn’t fail, the issue may be transient. Now, re-onboard the device on successful installation of python package.',
    ],
  },
  DEV_E104: {
    description: 'Docker Installation failed',
    actions: [
      'Ensure that device has an active internet connection',
      'Ensure that there is adequate disk space on device',

      'Ensure that the docker version on the device is one of the following: (1) docker-ce: 5:20.10.7~3-0~ubuntu-xenial for Ubuntu 16.04 (2) docker-ce: 5:20.10.7~3-0~ubuntu-bionic for Ubuntu 18.04 (3) docker-ce: 5:20.10.7~3-0~ubuntu-focal for Ubuntu 20.04. If not, uninstall it and re-onboard the device again',
    ],
  },
  DEV_E105: {
    description: 'Installing system package failed',
    actions: [
      'Ensure that device has an active internet connection',
      'Ensure that there is adequate disk space on device',
      `Try installing the package manually by executing 'apt-get install <system-package>' and see if it fails. If it doesn’t fail, the issue may be transient. Now, re-onboard the device on successful installation of system package.`,
    ],
  },
  DEV_E106: {
    description: 'Managing files on device failed',
    actions: [
      'Ensure that device has an active internet connection',
      'Ensure that there is adequate disk space on device',
    ],
  },
  DEV_E107: {
    description: 'Internal service failed to start',
    actions: ['Please contact rapyuta.io support.'],
  },
  DEV_E108: {
    description: 'Initialization time exceeded',
    actions: ['Re-onboard the device again', 'Please contact rapyuta.io support.'],
  },
  DEV_E109: {
    description: 'Initialization failed due to network error',
    actions: ['Ensure that device has an active internet connection'],
  },
  DEV_E110: {
    description: 'Initialization failed due to docker login error',
    actions: [
      'Ensure that device has an active internet connection',
      `Ensure that the package golang-docker-credential-helpers is not installed. If present remove it manually by executing 'apt-get remove golang-docker-credential-helpers'. Now, re-onboard the device again.`,
    ],
  },
};
