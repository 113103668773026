import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';

import { APICallStatus } from 'Models/common/types';
import { NotificationPayload } from 'Models/notifications/types';
import {
  getOrganizationDetails as getOrganizationDetailsAPI,
  updateOrganization as updateOrganizationAPI,
} from 'Models/organization/service';
import { OrgAction, UpdateOrgPayload } from 'Models/organization/types';
import { Project, UserProject } from 'Models/projects/types';
import {
  createUserGroup as createUserGroupAPI,
  deleteUserGroup as deleteUserGroupAPI,
  getUserGroupDetails as getUserGroupDetailsAPI,
  getUserGroupsList,
  leaveUserGroup as leaveUserGroupAPI,
  updateUserGroup as updateUserGroupAPI,
} from 'Models/userGroups/service';
import {
  CreateUserGroupData,
  CreateUserGroupReq,
  UpdateUserGroupData,
  UserGroup,
  UserGroupUser,
} from 'Models/userGroups/types';
import { getMessageFromError } from 'Shared/utils/common';
import { diff, getValueByKey } from 'Shared/utils/core';
import { CallReturnType } from 'Types/saga';

import { setNotificationFail, setNotificationSuccess } from '../notifications/action';

import {
  setCreateUserGroupAPIStatus,
  setDeleteUserGroupAPIStatus,
  setLeaveUserGroupAPIStatus,
  setOrgDetails,
  setOrgDetailsAPIStatus,
  setUpdateOrgAPIStatus,
  setUpdateOrgSliderVisibility,
  setUpdateUserGroupAPIStatus,
  setUserGroupDetailsAPIStatus,
  setUserGroupsAPIStatus,
  updateUserGroupDetails,
  updateUserGroupsList,
} from './actions';
import ActionTypes from './actionTypes';

const createErrorPayload = (error: AxiosError): NotificationPayload => ({
  message: error?.message,
  description: getMessageFromError(error),
});

function* getOrganizationDetails(action: OrgAction<string>) {
  try {
    yield put(setOrgDetailsAPIStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof getOrganizationDetailsAPI> = yield call(
      getOrganizationDetailsAPI,
      action.payload,
    );
    yield put(setOrgDetailsAPIStatus(APICallStatus.LOADED));
    yield put(setOrgDetails(data));
  } catch (error) {
    yield put(setOrgDetailsAPIStatus(APICallStatus.ERROR));
  }
}

function* getUserGroups() {
  try {
    yield put(setUserGroupsAPIStatus(APICallStatus.LOADING));

    const userGroups: CallReturnType<typeof getUserGroupsList> = yield call(getUserGroupsList);
    yield put(updateUserGroupsList(userGroups));

    yield put(setUserGroupsAPIStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setUserGroupsAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* getUserGroupDetails(action: OrgAction<string>) {
  try {
    yield put(setUserGroupDetailsAPIStatus(APICallStatus.LOADING));

    const userGroupDetails: CallReturnType<typeof getUserGroupDetailsAPI> = yield call(
      getUserGroupDetailsAPI,
      action.payload,
    );
    yield put(updateUserGroupDetails(userGroupDetails));

    yield put(setUserGroupDetailsAPIStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setUserGroupDetailsAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* createUserGroup(action: OrgAction<CreateUserGroupData>) {
  try {
    yield put(setCreateUserGroupAPIStatus(APICallStatus.LOADING));

    const { formData, selectedUsers, selectedProjects } = action.payload;
    const mapGuid = (str: string) => ({ guid: str });

    const members = getValueByKey<UserGroupUser>(selectedUsers, 'guid') as string[];
    const projects = getValueByKey<Project>(selectedProjects, 'guid') as string[];
    const filteredAdmins = selectedUsers.filter(({ isAdmin }) => isAdmin);
    const admins = getValueByKey<UserGroupUser>(filteredAdmins, 'guid') as string[];
    const userGroupRoleInProjects = selectedProjects.map(({ guid, role }) => ({
      projectGUID: guid,
      groupRole: role,
    }));

    const req: CreateUserGroupReq = {
      ...formData,
      members: members.map(mapGuid),
      projects: projects.map(mapGuid),
      admins: admins.map(mapGuid),
      userGroupRoleInProjects,
    };
    yield call(createUserGroupAPI, req);
    const successPayload: NotificationPayload = {
      message: `${formData.name} created successfully!`,
    };
    yield put(setNotificationSuccess(successPayload));
    yield put(setCreateUserGroupAPIStatus(APICallStatus.LOADED));
    yield put(push('/user_groups'));
  } catch (error) {
    yield put(setCreateUserGroupAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* updateUserGroup(action: OrgAction<UpdateUserGroupData>) {
  try {
    yield put(setUpdateUserGroupAPIStatus(APICallStatus.LOADING));

    const {
      userGroup: { guid, members, projects, admins },
      formData,
      selectedUserKeys,
      selectedProjectKeys,
      selectedAdminKeys,
      userGroupRoleInProjects,
    } = action.payload;

    const adminKeys = getValueByKey<UserGroupUser>(admins, 'guid') as string[];
    const memberKeys = getValueByKey<UserGroupUser>(members, 'guid') as string[];
    const projectKeys = getValueByKey<UserProject>(projects as UserProject[], 'guid') as string[];

    const mapGuid = (str: string) => ({ guid: str });

    const addMembers = diff(selectedUserKeys, memberKeys).map(mapGuid);
    const removeMembers = diff(memberKeys, selectedUserKeys).map(mapGuid);

    const addProjects = diff(selectedProjectKeys, projectKeys).map(mapGuid);
    const removeProjects = diff(projectKeys, selectedProjectKeys).map(mapGuid);

    const addAdmins = diff(selectedAdminKeys, adminKeys).map(mapGuid);
    const removeAdmins = diff(adminKeys, selectedAdminKeys).map(mapGuid);

    const req = {
      ...formData,
      guid,
      update: {
        members: { add: addMembers, remove: removeMembers },
        projects: { add: addProjects, remove: removeProjects },
        admins: { add: addAdmins, remove: removeAdmins },
      },
      userGroupRoleInProjects,
    };

    yield call(updateUserGroupAPI, req);
    const successPayload: NotificationPayload = {
      message: `${formData.name} updated successfully!`,
    };
    yield put(setNotificationSuccess(successPayload));
    yield put(setUpdateUserGroupAPIStatus(APICallStatus.LOADED));
    yield put(push('/user_groups'));
  } catch (error) {
    yield put(setUpdateUserGroupAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* deleteUserGroup(action: OrgAction<UserGroup>) {
  try {
    yield put(setDeleteUserGroupAPIStatus(APICallStatus.LOADING));
    yield call(deleteUserGroupAPI, action?.payload.guid);
    yield put(setDeleteUserGroupAPIStatus(APICallStatus.LOADED));
    const successPayload: NotificationPayload = {
      message: `${action.payload.name} deleted successfully!`,
    };
    yield put(setNotificationSuccess(successPayload));
  } catch (error) {
    yield put(setDeleteUserGroupAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* leaveUserGroup(action: OrgAction<UserGroup>) {
  try {
    yield put(setLeaveUserGroupAPIStatus(APICallStatus.LOADING));

    yield call(leaveUserGroupAPI, action?.payload.guid);
    yield put(setLeaveUserGroupAPIStatus(APICallStatus.LOADED));
    const successPayload: NotificationPayload = {
      message: `${action.payload.name} left successfully!`,
    };
    yield put(setNotificationSuccess(successPayload));
  } catch (error) {
    yield put(setLeaveUserGroupAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

function* updateOrg(action: OrgAction<UpdateOrgPayload>) {
  try {
    yield put(setUpdateOrgAPIStatus(APICallStatus.LOADING));
    const data: CallReturnType<typeof updateOrganizationAPI> = yield call(
      updateOrganizationAPI,
      action?.payload,
    );
    yield put(setUpdateOrgSliderVisibility(false));
    yield put(setOrgDetails(data));
    yield put(setUpdateOrgAPIStatus(APICallStatus.LOADED));
  } catch (error) {
    yield put(setUpdateOrgAPIStatus(APICallStatus.ERROR));
    const errorPayload = createErrorPayload(error as AxiosError);
    yield put(setNotificationFail(errorPayload));
  }
}

export default function* organizationSaga(): IterableIterator<ForkEffect<never>> {
  yield takeLatest(ActionTypes.GET_DETAILS, getOrganizationDetails);
  yield takeLatest(ActionTypes.GET_USERGROUPS_LIST, getUserGroups);
  yield takeLatest(ActionTypes.GET_USERGROUP_DETAILS, getUserGroupDetails);
  yield takeLatest(ActionTypes.CREATE_USERGROUP, createUserGroup);
  yield takeLatest(ActionTypes.UPDATE_USERGROUP, updateUserGroup);
  yield takeLatest(ActionTypes.DELETE_USERGROUP, deleteUserGroup);
  yield takeLatest(ActionTypes.LEAVE_USERGROUP, leaveUserGroup);
  yield takeLatest(ActionTypes.UPDATE_ORG, updateOrg);
}
