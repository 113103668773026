import Cookies from 'js-cookie';

import { User, UserMap } from 'Models/common/types';
import { getUrlParameter, replaceUrlParameters } from 'Shared/utils/common';

class StoreUtilAdapter {
  projectId = '';

  userToken?: string = Cookies.get('token');

  userMap: UserMap = {};

  userDetails: User | null = null;

  setSelectedProjectId(projectId: string) {
    this.projectId = projectId;
  }

  setUserDetails(userDetails: User) {
    this.userDetails = userDetails;
  }

  getSelectedProjectGUID() {
    const selectedProjectFromCookies = Cookies.get('project');
    const selectProjectFromParameter = getUrlParameter('p');
    const selectedProjectId =
      this.projectId ||
      selectProjectFromParameter ||
      (selectedProjectFromCookies?.length ? selectedProjectFromCookies : null);
    if (selectedProjectId !== selectProjectFromParameter) {
      replaceUrlParameters({ p: selectedProjectId });
    }

    return selectedProjectId === 'null' ? null : selectedProjectId;
  }

  getSelectedOrgID(): string {
    return getUrlParameter('org') ?? this.userDetails?.spec?.organizations?.[0]?.guid ?? '';
  }

  getUserToken(): string {
    return this.userToken ?? '';
  }

  setUserMap(userMap: UserMap) {
    this.userMap = userMap;
  }

  getUsernameFromGUID = (userId: string): string => {
    const userDetails = this.userMap[userId];
    if (userDetails) {
      return `${userDetails.firstName} ${userDetails.lastName}`;
    }

    return userId;
  };

  getUserName = () => {
    const { userDetails } = this;

    return userDetails?.metadata?.name || '';
  };
}

const storeUtilAdapterInst = new StoreUtilAdapter();

export default storeUtilAdapterInst;
