import { AppState, User, UserMap } from 'Models/common/types';
import { UserGroup } from 'Models/userGroups/types';

export const selectUserDetails = (state: AppState): User | null => state.common.userDetails;

export const selectUserMap = (state: AppState): UserMap => state.common.userMap;

export const selectCurrentOrgGUID = (state: AppState): string => state.common.selectedOrgGUID;

export const selectUserGroups = (state: AppState): UserGroup[] => state.organization.userGroups;

export const selectCurrentProjectGUID = (state: AppState): string =>
  state.common.selectedProjectGUID;
