import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';

import InitialLoader from 'Components/initialLoader';
import { APICallStatus, AppState, RIOError, User } from 'Models/common/types';
import { Project } from 'Models/projects/types';
import { getUserDetails } from 'RioRedux/common/actions';
import { listProjects } from 'RioRedux/projects/actions';
import { LAYOUT_ROOT_CLASS } from 'Shared/constants/layout';

import Routes from './Routes';

const { Content } = Layout;

const Unauthorized = lazy(() => import('./Unauthorized'));

interface Props {
  userToken: string;
  userDetails: User | null;
  isNewUser: boolean;
  userErrorMessage: string;
  projectListAPIStatus: APICallStatus;
  projectListError: RIOError | null;
  projects: Project[];
  selectedOrgGUID: string;
  getUserDetails: typeof getUserDetails;
  listProjects: (projectId: string) => void;
}

const rootClassName = LAYOUT_ROOT_CLASS;
const pageContainerClassName = `${rootClassName}__content`;

const ContentRenderer: React.FC<Props> = (props) => {
  const { userToken, userDetails, selectedOrgGUID } = props;

  if (userToken) {
    if (props.isNewUser) {
      return <Unauthorized />;
    }

    if (props.userErrorMessage || !userDetails?.metadata.guid) {
      return <InitialLoader error={props.userErrorMessage} tryAgain={props.getUserDetails} />;
    }

    const projectsFetching = props.projectListAPIStatus === APICallStatus.LOADING;
    const hasProjectsError = props.projectListAPIStatus === APICallStatus.ERROR;

    if (hasProjectsError) {
      return (
        <InitialLoader error={props.projectListError?.message} tryAgain={props.listProjects} />
      );
    }

    if (projectsFetching) {
      return <InitialLoader />;
    }
  }
  const isSelectedOrgPrimary = selectedOrgGUID === userDetails?.spec?.organizations?.[0]?.guid;

  return (
    <Content className={`${pageContainerClassName} antd-override`}>
      <Routes projects={props.projects} isSelectedOrgPrimary={isSelectedOrgPrimary} />
    </Content>
  );
};

const mapStateToProps = ({ common, projects }: AppState) => ({
  userToken: common.userToken,
  userDetails: common.userDetails,
  isNewUser: common.isNewUser,
  userErrorMessage: common.errorMessage,
  projectListAPIStatus: projects.listAPIStatus,
  projectListError: projects.error,
  projects: projects.list,
  selectedOrgGUID: common.selectedOrgGUID,
});

const actionCreators = {
  getUserDetails,
  listProjects,
};

export default connect(mapStateToProps, actionCreators)(ContentRenderer);
