/* eslint-disable */
import compareVersions from 'compare-versions';
import qs from 'qs';

import { ROSVersion } from 'Models/common/types';

import { AUTH_ENDPOINT } from '../config';

import { DISALLOW_PLUS_EMAIL_REGEX, EMAIL_REGEX } from './regex';

export const ROS_VERSIONS = {
  NOETIC: 'noetic',
  MELODIC: 'melodic',
  KINETIC: 'kinetic',
  FOXY: 'foxy',
};
export const DEFAULT_ROS_VERSION = ROSVersion.MELODIC;
export const EOL_ROS_VERSIONS = [ROSVersion.KINETIC];

export const DEVICE_RUNTIME = {
  PRE_INSTALLED: 'preinstalled',
  DOCKER_COMPOSE: 'dockercompose',
};

export const API_CALL_STATUS = {
  INITIAL: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3,
};

export const SUBSCRIBE_STATUS = {
  SUBSCRIBED: 0,
  SUBSCRIBING: 1,
  UNSUBSCRIBED: 2,
  UNSUBSCRIBING: 3,
};

export const isValidProjectname = (projectString) =>
  !projectString || /^[a-z0-9-]{3,63}$/.test(projectString);
export const isValidEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );

export const customSetCookie = (cname, cvalue, exAt, callback) => {
  const d = new Date();
  d.setTime(new Date(exAt));
  const expires = d.toUTCString();

  const domainName = window.location.hostname.split('.').slice(-2).join('.');

  document.cookie = `${cname}=${cvalue};expires=${expires};Domain=${domainName};Path=/;secure;`;
  if (callback && callback instanceof Function) {
    callback();
  }
};

export const nestedTrim = (input) => {
  if (typeof input === 'string') {
    return input.trim();
  }
  if (Array.isArray(input)) {
    return input.map((i) => nestedTrim(i));
  }
  if (typeof input === 'object') {
    const temp = { ...input };
    Object.keys(temp).forEach((i) => (temp[i] = nestedTrim(temp[i])));
    return temp;
  }
  return input;
};

export const searchObjectValues = (searchObject, searchString) =>
  Object.keys(searchObject).some((key) => {
    const currentItem = searchObject[key];
    return (
      (typeof currentItem === 'object' && searchObjectValues(currentItem, searchString)) ||
      (typeof currentItem === 'string' &&
        currentItem.toLocaleLowerCase().includes(searchString.toLowerCase()))
    );
  });

export const getUrlParameter = (paramName, url = window.location.href) => {
  const name = paramName.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getAllUrlParameters = (
  paramName,
  searchParams = window.location.search.substring(1),
) => {
  const params = new URLSearchParams(searchParams);
  return params.getAll(paramName);
};

export const setAllUrlParameters = (paramObject, url = window.location.href) => {
  const currentURL = new URL(url);
  Object.entries(paramObject).forEach(([paramName, paramValues]) => {
    if (Array.isArray(paramValues)) {
      currentURL.searchParams.delete(paramName);
      paramValues.forEach((paramValue) => currentURL.searchParams.append(paramName, paramValue));
    } else {
      currentURL.searchParams.set(paramName, paramValues);
    }
  });
  window.history.replaceState({}, '', currentURL.href);
};

export const generatePath = (params) => {
  const path = `${window.location.protocol}//${window.location.host}${window.location.pathname}${
    Object.keys(params).length > 0 ? '?' : ''
  }${qs.stringify(params)}`;
  window.history.replaceState({ path }, '', path);
};

export const replaceUrlParameters = (params) => {
  const urlParams = qs.parse(window.location.search.substring(1));
  const newParams = {
    ...urlParams,
    ...params,
  };
  generatePath(newParams);
};

export const removeUrlParameters = (params) => {
  const urlParams = qs.parse(window.location.search.substring(1));
  params.forEach((x) => {
    delete urlParams[x];
  });
  const newParams = {
    ...urlParams,
  };
  generatePath(newParams);
};

export const getRedirectPathWithQueryParams = () => {
  const {
    location: { pathname, search },
  } = window;
  const pathQueryParam = `${pathname !== '/' ? pathname : ''}${search ?? ''}`;

  return `?path=${encodeURIComponent(pathQueryParam || '/')}&service=ioconsole`;
};

export const redirectToAuth = (path) => {
  window.location.href = `${AUTH_ENDPOINT}${path}${getRedirectPathWithQueryParams()}`;
};

export const downloadXhrResponse = ({ url, params, methodType = 'GET', options = {} }) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(methodType, url, true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = () => {
      if (this.status >= 200 && this.status < 300) {
        resolve();
        let filename = '';
        const disposition = xhr.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }
        const type = xhr.getResponseHeader('Content-Type');

        const blob = new Blob([this.response], { type });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const URL = window.URL || window.webkitURL;
          const downloadUrl = URL.createObjectURL(blob);
          filename = filename || options.fallbackfilename;
          if (filename) {
            // use HTML5 a[download] attribute to specify filename
            const a = document.createElement('a');
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
              window.location = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location = downloadUrl;
          }
          setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
          }, 100);
        }
      } else {
        reject(new Error(xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(new Error(xhr.statusText));
    };
    xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
    if (options.headers) {
      Object.entries(options.headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });
    }
    xhr.send(JSON.stringify(params));
  });

export function isDataURL(s) {
  return !!s.match(isDataURL.regex);
}

// eslint-disable-next-line no-useless-escape
isDataURL.regex =
  /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+=[a-z\-]+)?)?(;base64)?,[a-z0-9!$&,'()*+;=\-._~:@\/?%\s]*\s*$/i;

export const alphaNumericDoesntBeginWithNumberRegex = new RegExp(/^[A-Za-z][a-zA-Z0-9_]*$/);

export const notNumberRegex = /[^0-9]/g;

export const deviceErrorStatuses = {
  initial: 'i',
  logsFetchProgress: 'p',
  logsFetched: 'l',
  logsFetchError: 'fe',
  errorReported: 'e',
};

export const downloadAsTxtFile = (filename, data) => {
  try {
    const element = document.createElement('a');
    const file = new Blob([data], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    // cleanup DOM littering
    setTimeout(() => {
      element.remove();
    }, 1000);
    return true;
  } catch (ex) {
    // TODO: SENTRY error should be raised here
    // For now this returns false instead
    return false;
  }
};

export const preventEventBubble = (event) => {
  event.stopPropagation();
};

export const isFullscreenToggleKeyCombo = (evt) => {
  let isEscape = false;
  // 'key' filed is deprecated in new versions, adding this to handle older browser compatibility
  if (evt?.key) {
    isEscape = evt.key === 'Escape' || evt.key === 'Esc';
  } else {
    isEscape = evt.keyCode === 27;
  }

  return evt.ctrlKey && isEscape;
};

export const DEFAULT_REACT_JOY_STYLE = { options: { zIndex: 99999 } };

export const triggerDownload = (url) => {
  try {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  } catch (ex) {
    console.error(ex);
    return false;
  }
};

export const ALL_RUNTIME_TYPE = 'All';
export const RUNTIMES = {
  device: 'device',
  cloud: 'cloud',
};

export const EXEC_TYPES = {
  docker: 'docker',
  default: 'default',
};

export const PARAMETERS = {
  DEVICE_ID: 'device_id',
};

export const getEncodedArrayUrlParams = (paramName, list) =>
  list.map((item) => `${paramName}=${encodeURIComponent(item)}`).join('&');

export const apiVersion2 = '2.0.0';

// eslint-disable-next-line camelcase
export const apiVersion2_1_0 = '2.1.0';

export const isResourceAllowedByApiVersion = (currentApiVersion, resourceApiVersion) => {
  if (compareVersions.compare(currentApiVersion, apiVersion2, '<')) {
    return currentApiVersion === resourceApiVersion;
  }

  return compareVersions.compare(resourceApiVersion, apiVersion2, '>=');
};

export const getMBFromBytes = (bytes = 0) => {
  if (bytes === 0) {
    return `${0} Byte`;
  }
  const dataMB = Math.round(bytes / (1024 * 1024));
  if (dataMB >= 1) {
    return `${dataMB} MB`;
  }
  const dataKB = Math.round(bytes / 1024);
  if (dataKB >= 1) {
    return `${dataKB} KB`;
  }
  const dataBytes = bytes;
  return `${dataBytes} Bytes`;
};

export const getMBFromBytesWithoutUnits = (bytes = 0) => {
  if (bytes === 0) {
    return 0;
  }
  const dataMB = Math.round(bytes / (1024 * 1024));
  if (dataMB >= 1) {
    return dataMB;
  }
  const dataKB = Math.round(bytes / 1024);
  if (dataKB >= 1) {
    return dataKB;
  }
  const dataBytes = bytes;
  return dataBytes;
};

export const DEFAULT_ROSBAG_UPLOAD_RATE = 1;

export const DEFAULT_ROSBAG_MOUNT_PATH = '/opt/rapyuta/volumes/rosbag';

export const getMessageFromError = (e) => e.response?.data?.error || e.message;

export const getApiCallErrorMessage = (error) => getMessageFromError(error);

export const mapFiltersToQueryFilter = (filters, searchFields = []) => ({
  $and: Object.entries(filters).reduce((acc, [k, v]) => {
    if (v) {
      if (searchFields.indexOf(k) !== -1) {
        acc.push({ [k]: { $like: `%${v[0]}%` } });
      } else {
        acc.push({ $or: v.map((str) => ({ [k]: str })) });
      }
    }
    return acc;
  }, []),
});

export const mapTableConfigToApiQuery = ({ pagination, sort, filters }, searchFields) => ({
  page: {
    size: pagination.pageSize,
    number: pagination.current,
  },
  sort,
  filter: mapFiltersToQueryFilter(filters, searchFields),
});

export const abbreviateNumber = (number) => {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
  const tier = Math.log10(Math.abs(number)) / 3 || 0;
  if (tier === 0) return number;
  const suffix = SI_SYMBOL[tier];
  const scale = 10 ** (tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(1) + suffix;
};

export const filterStringOptions = (input, option) =>
  option.label?.toLowerCase()?.includes(input.toLowerCase()) ||
  option.value.toLowerCase().includes(input.toLowerCase());

export const filterObjectOptions = (input, option) =>
  Object.values(option.children.props).some((o) => o.toLowerCase().includes(input.toLowerCase()));

export const getEmailRegex = () => {
  const { ALLOW_PLUS_EMAIL } = window.CONFIG;

  return ALLOW_PLUS_EMAIL ? EMAIL_REGEX : DISALLOW_PLUS_EMAIL_REGEX;
};
