enum OrgActionTypes {
  GET_DETAILS = 'ORG/GET_DETAILS',
  SET_DETAILS = 'ORG/SET_DETAILS',
  SET_DETAILS_API_STATUS = 'ORG/SET_DETAILS_API_STATUS',
  GET_USERGROUPS_LIST = 'ORG/GET_USERGROUPS_LIST',
  GET_USERGROUP_DETAILS = 'GET_USERGROUP_DETAILS',
  SET_USERGROUPS_API_STATUS = 'ORG/SET_USERGROUPS_API_STATUS',
  UPDATE_USERGROUPS_LIST = 'ORG/UPDATE_USERGROUPS_LIST',
  UPDATE_USERGROUP_DETAILS = 'UPDATE_USERGROUP_DETAILS',
  SET_USERGROUP_DETAILS_API_STATUS = 'SET_USERGROUP_DETAILS_API_STATUS',
  SET_REGISTRATION_STATUS = 'ORG/SET_REGISTRATION_STATUS',
  SET_CREATE_USERGROUP_API_STATUS = 'ORG/SET_CREATE_USERGROUP_API_STATUS',
  CREATE_USERGROUP = 'ORG/CREATE_USERGROUP',
  SET_UPDATE_USERGROUP_API_STATUS = 'ORG/SET_UPDATE_USERGROUP_API_STATUS',
  UPDATE_USERGROUP = 'ORG/UPDATE_USERGROUP',
  SET_DELETE_USERGROUP_API_STATUS = 'ORG/SET_DELETE_USERGROUP_API_STATUS',
  DELETE_USERGROUP = 'ORG/DELETE_USERGROUP',
  SET_LEAVE_USERGROUP_API_STATUS = 'ORG/SET_LEAVE_USERGROUP_API_STATUS',
  LEAVE_USERGROUP = 'ORG/LEAVE_USERGROUP',
  SET_ERROR = 'ORG/SET_ERROR',
  SET_UPDATE_ORG_SLIDER_VISIBILITY = 'ORG/SET_UPDATE_ORG_SLIDER_VISIBILITY',
  UPDATE_ORG = 'ORG/UPDATE_ORG',
  SET_UPDATE_ORG_API_STATUS = 'ORG/SET_UPDATE_ORG_API_STATUS',
}

export default OrgActionTypes;
