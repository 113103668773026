import { APICallStatus } from 'Models/common/types';
import { OrgAction, OrgActionPayload, Organization, OrgState } from 'Models/organization/types';
import { UserGroup, UserGroupWithProjectRole } from 'Models/userGroups/types';

import ActionTypes from './actionTypes';

export const initialState: OrgState = {
  apiStatus: APICallStatus.INITIAL,
  error: '',
  details: null,
  userGroups: [],
  userGroupDetails: null,
  userGroupsAPIStatus: APICallStatus.INITIAL,
  createUserGroupAPIStatus: APICallStatus.INITIAL,
  updateUserGroupAPIStatus: APICallStatus.INITIAL,
  deleteUserGroupAPIStatus: APICallStatus.INITIAL,
  leaveUserGroupAPIStatus: APICallStatus.INITIAL,
  userGroupDetailsAPIStatus: APICallStatus.INITIAL,
  updateOrgSliderVisibility: false,
  updateOrgAPIStatus: APICallStatus.INITIAL,
};

const organizationReducer = (
  state: OrgState = initialState,
  action: OrgAction<OrgActionPayload>,
): OrgState => {
  switch (action.type) {
    case ActionTypes.SET_DETAILS: {
      return {
        ...state,
        details: action.payload as Organization,
      };
    }

    case ActionTypes.SET_DETAILS_API_STATUS: {
      return {
        ...state,
        apiStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_USERGROUPS_API_STATUS: {
      return {
        ...state,
        userGroupsAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_CREATE_USERGROUP_API_STATUS: {
      return {
        ...state,
        createUserGroupAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_UPDATE_USERGROUP_API_STATUS: {
      return {
        ...state,
        updateUserGroupAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_DELETE_USERGROUP_API_STATUS: {
      return {
        ...state,
        deleteUserGroupAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_LEAVE_USERGROUP_API_STATUS: {
      return {
        ...state,
        leaveUserGroupAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.UPDATE_USERGROUPS_LIST: {
      return {
        ...state,
        userGroups: action.payload as UserGroup[],
      };
    }

    case ActionTypes.UPDATE_USERGROUP_DETAILS: {
      return {
        ...state,
        userGroupDetails: action.payload as UserGroupWithProjectRole,
      };
    }

    case ActionTypes.SET_USERGROUP_DETAILS_API_STATUS: {
      return {
        ...state,
        userGroupDetailsAPIStatus: action.payload as APICallStatus,
      };
    }

    case ActionTypes.SET_ERROR: {
      return {
        ...state,
        error: action.payload as string,
      };
    }

    case ActionTypes.SET_UPDATE_ORG_SLIDER_VISIBILITY: {
      return {
        ...state,
        updateOrgSliderVisibility: action.payload as boolean,
      };
    }

    case ActionTypes.SET_UPDATE_ORG_API_STATUS: {
      return {
        ...state,
        updateOrgAPIStatus: action.payload as APICallStatus,
      };
    }

    default:
      return state;
  }
};

export default organizationReducer;
