import { APICallStatus } from 'Models/common/types';
import { OrgAction, Organization, UpdateOrgPayload } from 'Models/organization/types';
import {
  CreateUserGroupData,
  UpdateUserGroupData,
  UserGroup,
  UserGroupWithProjectRole,
} from 'Models/userGroups/types';

import ActionTypes from './actionTypes';

export const getOrgDetails = (payload: string): OrgAction<string> => ({
  type: ActionTypes.GET_DETAILS,
  payload,
});

export const getUserGroups = (): OrgAction => ({
  type: ActionTypes.GET_USERGROUPS_LIST,
});

export const getUserGroupDetails = (payload: string): OrgAction<string> => ({
  type: ActionTypes.GET_USERGROUP_DETAILS,
  payload,
});

export const setUserGroupDetailsAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_USERGROUP_DETAILS_API_STATUS,
  payload,
});

export const createUserGroup = (payload: CreateUserGroupData): OrgAction<CreateUserGroupData> => ({
  type: ActionTypes.CREATE_USERGROUP,
  payload,
});

export const updateUserGroup = (payload: UpdateUserGroupData): OrgAction<UpdateUserGroupData> => ({
  type: ActionTypes.UPDATE_USERGROUP,
  payload,
});

export const deleteUserGroup = (payload: UserGroup): OrgAction<UserGroup> => ({
  type: ActionTypes.DELETE_USERGROUP,
  payload,
});

export const leaveUserGroup = (payload: UserGroup): OrgAction<UserGroup> => ({
  type: ActionTypes.LEAVE_USERGROUP,
  payload,
});

export const setCreateUserGroupAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_CREATE_USERGROUP_API_STATUS,
  payload,
});

export const setUpdateUserGroupAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_UPDATE_USERGROUP_API_STATUS,
  payload,
});

export const setDeleteUserGroupAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_DELETE_USERGROUP_API_STATUS,
  payload,
});

export const setLeaveUserGroupAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_LEAVE_USERGROUP_API_STATUS,
  payload,
});

export const setOrgDetails = (payload: Organization): OrgAction<Organization> => ({
  type: ActionTypes.SET_DETAILS,
  payload,
});

export const setOrgDetailsAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_DETAILS_API_STATUS,
  payload,
});

export const setUserGroupsAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_USERGROUPS_API_STATUS,
  payload,
});

export const updateUserGroupsList = (payload: UserGroup[]): OrgAction<UserGroup[]> => ({
  type: ActionTypes.UPDATE_USERGROUPS_LIST,
  payload,
});

export const updateUserGroupDetails = (
  payload: UserGroupWithProjectRole,
): OrgAction<UserGroupWithProjectRole> => ({
  type: ActionTypes.UPDATE_USERGROUP_DETAILS,
  payload,
});

export const setError = (payload: string): OrgAction<string> => ({
  type: ActionTypes.SET_ERROR,
  payload,
});

export const setUpdateOrgSliderVisibility = (payload: boolean): OrgAction<boolean> => ({
  type: ActionTypes.SET_UPDATE_ORG_SLIDER_VISIBILITY,
  payload,
});

export const updateOrg = (payload: UpdateOrgPayload): OrgAction<UpdateOrgPayload> => ({
  type: ActionTypes.UPDATE_ORG,
  payload,
});

export const setUpdateOrgAPIStatus = (payload: APICallStatus): OrgAction<APICallStatus> => ({
  type: ActionTypes.SET_UPDATE_ORG_API_STATUS,
  payload,
});
