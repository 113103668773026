import axios from 'axios';
import Cookies from 'js-cookie';

import { customSetCookie, redirectToAuth, replaceUrlParameters } from './common';
import storeUtilAdapterInst from './storeUtilsAdapter';

const { CONFIG } = window;

// TODO: replace with UserRoles enum where ever possible
export const ROLES = {
  ADMIN: 'Admin',
};

// TODO: replace with EntityState enum where ever possible
export const ACCOUNT_STATE = {
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
  INVITED: 'INVITED',
  REGISTERED: 'REGISTERED',
  SUSPENDED: 'SUSPENDED',
};

export const ORGANIZATION_STATES = {
  ACTIVATED: 'ACTIVATED',
};

export const PRICING_PLAN_TYPES = {
  custom: 'custom',
  public: 'public',
};

const logoutBaseSteps = () => {
  customSetCookie('project', '');
  customSetCookie('organization', '');
};

export const logoutUser = () => {
  logoutBaseSteps();
  redirectToAuth('logout');
};

export const hardLogout = () => {
  logoutBaseSteps();
  replaceUrlParameters({ p: '', org: '' });
  redirectToAuth('logout');
};

export const isCustomPricingPlan = (orgDetails) =>
  orgDetails.state === ORGANIZATION_STATES.ACTIVATED &&
  orgDetails.planMetaData?.plan?.planType.toLowerCase() === PRICING_PLAN_TYPES.custom;

export const getTokenOrLogout = () => {
  const userToken = storeUtilAdapterInst.getUserToken();
  if (userToken !== Cookies.get('token')) {
    logoutUser();
  }
  return userToken;
};

// Currently the organization is needed for all the v2 api
export const authAxios = (options, projectHeader = true, orgHeader = false) => {
  const [userToken, project, organization] = [
    getTokenOrLogout(),
    storeUtilAdapterInst.getSelectedProjectGUID(),
    storeUtilAdapterInst.getSelectedOrgID(),
  ];
  return axios({
    ...options,
    headers: {
      ...(orgHeader && organization ? { organizationguid: organization } : {}),
      ...(projectHeader && project ? { project } : {}),
      Authorization: `Bearer ${userToken}`,
      ...options.headers,
    },
  });
};

export const authAxiosWithOrganizationHeader = (options) => {
  const [organization] = [storeUtilAdapterInst.getSelectedOrgID()];
  return authAxios(
    {
      ...options,
      headers: {
        ...(organization ? { organization } : {}),
        ...options.headers,
      },
    },
    false,
    false,
  );
};

export const getLoginURL = (pathParam = '/') =>
  `${CONFIG.RIP_FRONTEND_ENDPOINT || ''}login?path=${pathParam}&service=ioconsole`;
