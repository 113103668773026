import React, { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import classNames from 'classnames';

import EmbedDeviceSelector from 'Components/embedDeviceSelector';
import AppLoader from 'Components/loader';
import ToastNotification from 'Components/toastNotifications';
import { LAYOUT_ROOT_CLASS, LAYOUT_SCROLLER_ID } from 'Constants/layout';
import { APICallStatus, AppState, User } from 'Models/common/types';
import { Organization } from 'Models/organization/types';
import { Project } from 'Models/projects/types';
import { getUserDetails, initPendo } from 'RioRedux/common/actions';
import { listProjects } from 'RioRedux/projects/actions';
import useEmbedParams from 'Shared/hooks/useEmbedParams';

import ContentRenderer from './ContentRenderer';
import HeaderRenderer from './HeaderRenderer';

const AuthSidebar = lazy(() => import('./AuthSidebar'));

interface Props {
  isNewUser: boolean;
  orgDetails: Organization | null;
  projects: Project[];
  projectAPIStatus: APICallStatus;
  userDetails: User | null;
  userToken: string;
  getUserDetails: typeof getUserDetails;
  listProjects: (projectId: string) => void;
  initPendo: typeof initPendo;
}

const rootClassName = LAYOUT_ROOT_CLASS;
const rootContentCName = `${LAYOUT_ROOT_CLASS}__inner`;

const AppLayout: React.FC<Props> = (props) => {
  const { userToken, userDetails, projects } = props;
  const params = useEmbedParams();
  const embed = params.embed === 'true';
  const hasSelectors = Boolean(params.selector?.length);
  const userGUID = userDetails?.metadata.guid;

  useEffect(() => {
    if (userToken) {
      props.getUserDetails();
    }
  }, [userToken]);

  useEffect(() => {
    if (userGUID) {
      props.initPendo(userGUID);
    }
  }, [userGUID]);

  useEffect(() => {
    // List projects only if the user is part of an organization
    if (userGUID && !props.isNewUser) {
      props.listProjects(userGUID);
    }
  }, [userGUID, userDetails?.spec?.organizations?.[0]?.guid]);

  return (
    <Suspense fallback={<AppLoader />}>
      <Layout
        className={classNames(rootClassName, 'antd-override', {
          [`${rootClassName}--has-embed`]: embed,
        })}
      >
        {userToken && !embed && <AuthSidebar />}
        <Layout
          id={LAYOUT_SCROLLER_ID}
          className={classNames(rootContentCName, 'antd-override', {
            [`${rootContentCName}--has-embed`]: embed,
            [`${rootContentCName}--has-selectors`]: hasSelectors,
            [`${rootContentCName}--non-auth`]: !userToken,
            [`${rootContentCName}--has-header`]: userToken,
          })}
        >
          {!embed && <HeaderRenderer userToken={userToken} userFilteredProjects={projects} />}
          {embed && hasSelectors && (
            <div className={`${rootClassName}__embed-selectors`}>
              <EmbedDeviceSelector />
            </div>
          )}
          <ContentRenderer />
        </Layout>
      </Layout>

      <ToastNotification />
    </Suspense>
  );
};

const mapStateToProps = ({ common, projects, organization }: AppState) => ({
  userToken: common.userToken,
  userDetails: common.userDetails,
  orgDetails: organization.details,
  projects: projects.list,
  projectAPIStatus: projects.apiStatus,
  isNewUser: common.isNewUser,
});

const actionCreators = {
  getUserDetails,
  listProjects,
  initPendo,
};

export default connect(mapStateToProps, actionCreators)(AppLayout);
