import { ViewType } from '../newComponents/ToggleView';

export const SIDEBAR_WIDTH = 80; // Keep this in sync with $side-navbar-width
export const SIDEBAR_EXPANDED_WIDTH = 280; // Keep this in sync with $side-navbar-expanded-width

export const AUTH_HEADER_HEIGHT = 80; // Keep this in sync with $auth-header-height

export const NON_AUTH_HEADER_HEIGHT = 106; // Keep this in sync with $non-auth-header-height

export const LAYOUT_ROOT_CLASS = 'rio-app-layout';
export const LAYOUT_SCROLLER_ID = 'rio-app-layout-scroller';
export const LAYOUT_SCROLLER_CLASS = 'rio-app-layout__inner';
export const AUTH_HEADER_LOGO_CLASSNAME = 'rio-auth-header__logo';

export const SCROLL_THROTTLE_DURATION = 200;

export const SCROLLTOP_BUFFER = 120;

export const AUTH_LANDING_PAGE_ROUTE = '/dashboard';
export const LOGIN_ROUTE = '/login';

export const AUTH_SIDEBAR_ID = 'rio-auth-sidebar';

export const DEFAULT_APP_TITLE = 'rapyuta.io';

export const DEFAULT_VIEW_TYPE = ViewType.LIST;

export const METRICS_TABLE_NAME_COLUMN_WIDTH = 116; // Keep this in sync with $metrics-table-name-column-width
