import { APIOrganization, Organization } from './types';

export const mapAPIOrgToOrg = ({
  ID,
  CreatedAt,
  UpdatedAt,
  DeletedAt,
  ...rest
}: APIOrganization): Organization => ({
  ...rest,
  id: ID,
  createdAt: CreatedAt,
  deletedAt: DeletedAt,
  updatedAt: UpdatedAt,
});

export default {};
