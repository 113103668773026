import axios from 'axios';

import { USER_DETAILS_URL } from 'Shared/config';
import { authAxios } from 'Shared/utils/user';

import { AuthTokenFormData, UpdateProfilePayload, User } from './types';

export const getUserDetails = async (): Promise<User> =>
  authAxios(
    {
      method: 'GET',
      url: USER_DETAILS_URL,
    },
    false,
    false,
  ).then((response) => response.data);

export const updateUserDetails = (data: UpdateProfilePayload): Promise<User> =>
  authAxios(
    {
      method: 'PUT',
      url: USER_DETAILS_URL,
      data,
    },
    false,
    false,
  ).then((response) => response.data);

export const getAuthToken = async (data: AuthTokenFormData): Promise<string> =>
  axios({
    method: 'POST',
    url: `${window.CONFIG.RIP_ENDPOINT}user/login`,
    params: {
      type: 'high',
    },
    data,
  }).then((response) => response.data.data.token);
