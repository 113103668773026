enum ActionTypes {
  GET_AUTH_TOKEN = 'COMMON/GET_AUTH_TOKEN',
  SET_AUTH_TOKEN_API_STATUS = 'COMMON/SET_AUTH_TOKEN_API_STATUS',
  SET_AUTH_TOKEN_API_ERROR = 'COMMON/SET_AUTH_TOKEN_API_ERROR',
  UPDATE_AUTH_TOKEN = 'COMMON/UPDATE_AUTH_TOKEN',
  GET_USER_DETAILS = 'COMMON/GET_USER_DETAILS',
  SET_USER_DETAILS = 'COMMON/SET_USER_DETAILS',
  SET_USER_DETAILS_API_STATUS = 'COMMON/SET_USER_DETAILS_API_STATUS',
  SAVE_USER_DETAILS = 'COMMON/SAVE_USER_DETAILS',
  UPDATE_USER_DETAILS = 'COMMON/UPDATE_USER_DETAILS',
  SET_UPDATE_USER_DETAILS_API_STATUS = 'COMMON/SET_UPDATE_USER_DETAILS_API_STATUS',
  SET_USER_MAP = 'COMMON/SET_USER_MAP',
  UPDATE_USER_MAP = 'COMMON/UPDATE_USER_MAP',
  PROJECTS_GET_FULFILLED = 'COMMON/PROJECTS_GET_FULFILLED',
  CHANGE_SELECTED_PROJECT = 'COMMON/CHANGE_SELECTED_PROJECT',
  UPDATE_SELECTED_PROJECT = 'COMMON/UPDATE_SELECTED_PROJECT',
  SET_COMMON_API_STATUS = 'COMMON/SET_COMMON_API_STATUS',
  SET_COMMON_ERROR_MESSAGE = 'COMMON/SET_COMMON_ERROR_MESSAGE',
  SET_NEW_USER_FLAG = 'COMMON/SET_NEW_USER_FLAG',
  COPY_TEXT = 'COMMON/COPY_TEXT',
  CHANGE_SELECTED_ORG = 'COMMON/CHANGE_SELECTED_ORG',
  UPDATE_SELECTED_ORG = 'COMMON/UPDATE_SELECTED_ORG',
  INIT_PENDO = 'COMMON/INIT_PENDO',
  UPDATE_PROJECTS_AND_SELECTED_PROJECT = 'COMMON/UPDATE_PROJECTS_AND_SELECTED_PROJECT',
  SET_PROFILE_DRAWER_VISIBILITY = 'COMMON/SET_PROFILE_DRAWER_VISIBILITY',
}

export default ActionTypes;
