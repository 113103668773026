import React, { ReactNode } from 'react';
import { TablePaginationConfig } from 'antd/lib/table';
import { Key } from 'antd/lib/table/interface';
import { AxiosError } from 'axios';
import { RouterAction, RouterState } from 'connected-react-router';
import { LocationState } from 'history';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { DeploymentActionPayload, DeploymentState } from 'Models/deployments/types';
import { DevicesActionPayload, DevicesState } from 'Models/devices/types';
import { NotificationPayload, NotificationState } from 'Models/notifications/types';
import { OrgActionPayload, OrgState, UserData } from 'Models/organization/types';
import { Project, ProjectsActionPayload, ProjectsState, ProjectUser } from 'Models/projects/types';
import { SecretActionPayload, SecretState } from 'Models/secrets/types';
import { StaticRoutesPayload, StaticRouteState } from 'Models/staticRoutes/types';
import CLIManifestActionTypes from 'RioRedux/cliManifest/actionTypes';
import CommonActionTypes from 'RioRedux/common/actionTypes';
import ConfigurationActionTypes from 'RioRedux/configurations/actionTypes';
import DeploymentActionTypes from 'RioRedux/deployments/actionTypes';
import DevicesActionTypes from 'RioRedux/devices/actionTypes';
import DisksActionTypes from 'RioRedux/disks/actionTypes';
import NotificationActionTypes from 'RioRedux/notifications/actionTypes';
import OrgActionTypes from 'RioRedux/organization/actionTypes';
import ProjectsActionTypes from 'RioRedux/projects/actionTypes';
import SecretsActionTypes from 'RioRedux/secrets/actionTypes';
import StaticRouteActionTypes from 'RioRedux/staticRoutes/actionTypes';
import DeployPackageActionTypes from 'Root/redux/deployPackage/actionTypes';
import NetworkActionTypes from 'Root/redux/network/actionTypes';
import V2PackagesActionTypes from 'Root/redux/packages/actionTypes';
import { USERROLE } from 'Shared/types';

import { CLIManifestActionPayload } from '../cliManifest/types';
import { ConfigurationActionPayload, ConfigurationState } from '../configurations/types';
import { DeployPackageState } from '../deployPackage/types';
import { DisksActionPayload, DisksState } from '../disks/types';
import { NetworkPayload, NetworkState } from '../networks/types';
import { PackagesPayload, PackagesState } from '../packages/types';

// TODO : these doesn't belong here move it to appropriate place

export interface AppState {
  readonly router: RouterState<LocationState>;
  readonly common: CommonState;
  readonly configurations: ConfigurationState;
  readonly organization: OrgState;
  readonly projects: ProjectsState;
  readonly deployments: DeploymentState;
  readonly devices: DevicesState;
  readonly disks: DisksState;
  readonly secrets: SecretState;
  readonly staticRoutes: StaticRouteState;
  readonly notification: NotificationState;
  readonly packages: PackagesState;
  readonly deployPackage: DeployPackageState;
  readonly network: NetworkState;
}

export type AppActionTypes =
  | CommonActionTypes
  | ConfigurationActionTypes
  | DevicesActionTypes
  | DisksActionTypes
  | ProjectsActionTypes
  | DeploymentActionTypes
  | OrgActionTypes
  | SecretsActionTypes
  | StaticRouteActionTypes
  | NotificationActionTypes
  | CLIManifestActionTypes
  | V2PackagesActionTypes
  | DeployPackageActionTypes
  | NetworkActionTypes;

export type AppActionPayloads =
  | CommonActionPayload
  | ConfigurationActionPayload
  | DevicesActionPayload
  | DisksActionPayload
  | ProjectsActionPayload
  | DeploymentActionPayload
  | OrgActionPayload
  | SecretActionPayload
  | StaticRoutesPayload
  | NotificationPayload
  | CLIManifestActionPayload
  | PackagesPayload
  | NetworkPayload;

export type AppAction =
  | {
      type: AppActionTypes;
      payload?: AppActionPayloads;
    }
  | RouterAction<LocationState>;

export type RIOThunkAction = ThunkAction<void, AppState, unknown, AppAction>;
export type RIOThunkDispatch = ThunkDispatch<AppState, unknown, AppAction>;

export interface CommonState {
  userToken: string;
  authToken: string;
  authTokenAPIStatus: APICallStatus;
  authTokenAPIError: string;
  username: string;
  userDetails: User | null;
  userDetailsAPIStatus: APICallStatus;
  userMap: UserMap;
  selectedProjectGUID: string;
  selectedOrgGUID: string;
  isNewUser: boolean;
  apiStatus: APICallStatus;
  errorMessage: string;
  updateUserDetailsAPIStatus: APICallStatus;
  profileDrawerVisibility: boolean;
}

export interface Content {
  heading: string;
  matter: React.FC;
}

export type UserMap = Record<string, ProjectUser>;

export type UserWithRole = UserData & { role: USERROLE };

export enum EntityState {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  INVITED = 'INVITED',
  REGISTERED = 'REGISTERED',
  SUSPENDED = 'SUSPENDED',
}

export type CommonActionPayload =
  | User
  | UserMap
  | string
  | boolean
  | Project[]
  | APICallStatus
  | CopyToClipboardPayload
  | User[]
  | UpdateProjectsAndSelectedProjectPayload;

interface BaseCommonAction {
  type: CommonActionTypes;
}

export type CommonAction<P = undefined> = P extends undefined
  ? BaseCommonAction
  : BaseCommonAction & { payload: P };

export enum APICallStatus {
  INITIAL,
  LOADING,
  LOADED,
  ERROR,
}

export interface RIOError {
  status?: number;
  message: string;
}

export interface APIErrorData {
  error: string;
}

export type APIError = AxiosError<APIErrorData>;

export enum RunTime {
  DEVICE = 'device',
  CLOUD = 'cloud',
}
export interface APIHeaders {
  project?: string;
}

export interface Limits {
  cpu: number;
  memory: number;
}

export type FileReqHeaders = {
  'Content-Type'?: string;
  'X-Rapyuta-Params-Version'?: number;
  Checksum?: string;
};

export type IOHeaders = {
  Authorization: string;
  project?: string;
  'retry-after'?: boolean;
} & FileReqHeaders;

export enum TrackTypes {
  SUCCESS = 'success',
  ERROR = 'error',
}
export type TrackFnParam = {
  category: string;
  event: string;
  startDate: Date;
};
export type TableFilters = Record<string, (boolean | Key)[] | null>;

export interface TableQuery {
  pagination: TablePaginationConfig;
  filters: TableFilters;
  sort: string;
}

export interface KeyValue<T> {
  key: string;
  value: T;
}

export enum SortDirection {
  ASCEND = 'ascend',
  DESCEND = 'descend',
}
export enum BannerTypes {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  QUESTION = 'question',
}

export interface BannerConfigItem {
  message: string;
  content?: string[];
  priority: number;
  type: BannerTypes;
  showAfter?: string;
  hideAfter?: string;
  localStorageKey: string;
  title: string;
  visible: boolean;
}

export interface SelectOption {
  label: string;
  value: string;
  key?: string;
  children?: string;
}
export interface RjsfError {
  name: string;
  property: string;
  message: string;
  stack: string;
}

export interface RsjfObject<T> {
  formData: T;
}

export type RjsfFieldValidation = {
  __errors: string[];
  addError: (message: string) => void;
} & {
  [key: string]: RjsfFieldValidation;
};

export enum ROSVersion {
  FOXY = 'foxy',
  NOETIC = 'noetic',
  MELODIC = 'melodic',
  KINETIC = 'kinetic',
}

export interface CopyToClipboardPayload {
  content: string;
  title: string;
  showNotification: boolean;
}

export interface RosDetail {
  compression: string;
  name: string;
  qos: string;
  scoped: boolean;
  targeted: boolean;
}

export type TopicDetail = RosDetail & { topicName: string };
export type ServiceDetail = RosDetail & { serviceName: string; timeout: boolean };
export type ActionDetail = RosDetail & { actionName: string };

export interface ProfileFormData {
  firstName: string;
  lastName: string;
  emailID: string;
}

export interface EmailChangeFormData {
  emailID?: string;
  password?: string;
}

export interface AuthTokenFormData {
  email: string;
  password: string;
}

export interface HyperLinkInfo {
  to: string;
  icon: ReactNode;
  text: string;
}
export interface ErrorInfo {
  description: string;
  actions: string[];
}

export type ErrorMap = Record<string, ErrorInfo>;

export interface UpdateProjectsAndSelectedProjectPayload {
  projects: Project[];
  selectedProjectGUID: string;
}

export interface User {
  metadata: Metadata;
  spec: UserSpec;
}

interface Metadata {
  name: string;
  guid: string;
  createdAt: string;
  updatedAt: string;
}

interface UserSpec {
  firstName: string;
  lastName: string;
  emailID: string;
  projects: Project[];
  organizations?: Organization[];
  userGroupsMembers: UserGroup[];
  userGroupAdmins: UserGroup[];
}

interface Organization {
  guid: string;
  name: string;
  creator: string;
  shortGUID: string;
}

interface UserGroup {
  GUID: string;
  name: string;
  creator: string;
  organizationGUID: string;
  organizationCreatorGUID: string;
}

export interface UpdateProfilePayload {
  spec: {
    firstName?: string;
    lastname?: string;
    email?: string;
    password?: string;
  };
}
