import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';

import ExternalRedirect from 'Components/externalRedirect';
import { AppState, User } from 'Models/common/types';
import { LOGIN_ROOT_URL } from 'Shared/config';
import { getRedirectPathWithQueryParams } from 'Shared/utils/common';

interface Props {
  userToken: string;
  userDetails: User | null;
}

/* eslint-disable react/jsx-props-no-spreading */
const AuthenticatedRoute: React.FC<Props & RouteProps> = ({
  userToken,
  userDetails,
  component: Component,
  ...routerProps
}) => (
  <Route
    {...routerProps}
    render={(props) =>
      userToken && userDetails?.metadata.guid ? (
        Component && <Component {...props} />
      ) : (
        <ExternalRedirect location={`${LOGIN_ROOT_URL}${getRedirectPathWithQueryParams()}`} />
      )
    }
  />
);

const mapStateToProps = ({ common: { userToken, userDetails } }: AppState) => ({
  userToken,
  userDetails,
});
/* eslint-enable */

export default connect(mapStateToProps)(AuthenticatedRoute);
